/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {
  useContext,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from 'react-device-detect';
// Store
import { store } from '../state/store';

const useStyles = makeStyles((theme) => ({
  footer: {
    // backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(8),
    backgroundColor: '#dbdee0',
    color: '#56676b',
    padding: theme.spacing(6, 0),
  },
  font: {
    color: '#56676b',
  },
  fontBold: {
    color: '#56676b',
    fontWeight: 'bold',
  },
}));

export default function Footer() {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: isMobile ? 'top' : 'bottom',
          horizontal: 'left',
        }}
        open={state.notification}
        autoHideDuration={5000}
        onClose={() => {
          dispatch({
            type: 'set-notification',
            value: false,
          });
        }}
        message={state.notificationMessage}
        action={(
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                dispatch({
                  type: 'set-notification',
                  value: false,
                });
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
      />
    </>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import { IntlProvider } from 'react-intl';
import Main from './pages/Main';
import reportWebVitals from './reportWebVitals';

import { StateProvider } from './state/store';

const messagesInFrench = {
  myMessage: "Aujourd'hui, c'est le {ts, date, ::yyyyMMdd}",
};

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <IntlProvider messages={messagesInFrench} locale="es" defaultLocale="es">
        <Main />
      </IntlProvider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  LinearProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Chip,
  Divider,
} from '@material-ui/core';
import { QRCode } from 'react-qr-svg';
import QrReader from 'react-qr-scanner';
import axios from 'axios';
import { isMobile, isSafari } from 'react-device-detect';
import dayjs from 'dayjs';
import {
  Close,
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function DialogQRScanner(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Citas
  const [valid, setValid] = useState([]);
  const [all, setAll] = useState([]);
  const [showSelect, setShowSelect] = useState(false);

  const getData = async () => {
    setLoading(true);
    const token = await Auth.currentSession()
      .then((data) => data.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/citas',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      const citasValidas = result.Items.filter((e) => e.pago === 1 && e.status === 0)
        .sort((a, b) => b.citaId - a.citaId);
      const todasLasCitas = result.Items.sort((a, b) => b.citaId - a.citaId)
        .sort((a, b) => b.citaId - a.citaId);
      const multi = citasValidas.length > 1 ? true : todasLasCitas.length > 1;

      console.log(citasValidas.length > 1);

      console.log(todasLasCitas.length > 1);
      console.log(citasValidas);
      console.log(todasLasCitas);
      console.log(multi);
      setValid(citasValidas);
      setAll(todasLasCitas);
      setShowSelect(multi);
    }
    setLoading(false);
  };

  const clearForm = () => {
    setLoading(false);
    setSuccess(false);
    setShowSelect(false);
    setValid([]);
    setAll([]);
  };
  const setSelected = (e) => {
    setValid([e]);
    setShowSelect(false);
  };
  useEffect(() => {
    clearForm();
    getData();
  }, []);

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        open={state.dialogQRScanner}
        onClose={() => {
          dispatch({
            type: 'set-dialogQRScanner',
            value: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              {
                isMobile ? 'Aviso' : isSafari ? 'Advertencia' : 'Escanear Código QR'
              }
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogQRScanner',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {
                isMobile
                  ? (
                    <DialogContentText>
                      ⚠️Utilizar la aplicacion nativa de cámara, para escanear el códigos  QR.
                    </DialogContentText>
                  ) : isSafari ? (
                    <DialogContentText>
                      ⚠️Navegador no compatible, utilizar Google Chrome Firefox o IE Edge.
                    </DialogContentText>
                  ) : (
                    <QrReader
                      delay={100}
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                      onError={(err) => {
                        console.log(err);
                      }}
                      onScan={(qr) => {
                        if (qr) {
                          console.log(qr);

                          const query = new URLSearchParams(qr.replace('https://www.privatehc.com?', ''));
                          const accountEmail = query.get('accountEmail');
                          const citaId = query.get('citaId');
                          if (citaId && accountEmail) {
                            dispatch({
                              type: 'set-citasActiveInfo',
                              value: {
                                accountEmail,
                                citaId,
                              },
                            });
                            dispatch({
                              type: 'set-dialogCitasActive',
                              value: true,
                            });
                            dispatch({
                              type: 'set-dialogQRScanner',
                              value: false,
                            });
                          }
                        }
                      }}
                    />
                  )
              }
        </DialogContent>
        <DialogActions>

          <Button
            onClick={() => {
              dispatch({
                type: 'set-dialogQRScanner',
                value: false,
              });
            }}
            color="primary"
          >
            Cerrar
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogQRScanner;

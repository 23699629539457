/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Container,
  Fab,
  Zoom,
  useScrollTrigger,
} from '@material-ui/core';
import {
  KeyboardArrowUp, Today, People, Apartment, NavigateBefore, BorderClear,
} from '@material-ui/icons';
// Components
import Amplify, { Auth } from 'aws-amplify';
import useLocalStorage from 'react-use-localstorage';
import { withAuthenticator, Greetings } from 'amplify-material-ui';
import DialogQR from '../components/DialogQR';
import DialogNewUser from '../components/DialogNewUser';
import DialogCitasActive from '../components/DialogCitasActive';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Notification from '../components/Notification';
import DialogQRScanner from '../components/DialogQRScanner';
import DialogPayCita from '../components/DialogPayCita';

// Pages
import Registro from './Registro';
import AvisoPrivacidad from './AvisoPrivacidad';
import Disclaimer from './Disclaimer';
import TerminosDeUso from './TerminosDeUso';
import Home from './Home';
import Apointment from './Apointment';
import Places from './Places';
import PlacesSimple from './PlacesSimple';
import PowerUserHome from './PowerUserHome';
import Citas from './Citas';
import Reporting from './Reporting';
import PowerUsers from './PowerUsers';

// Store
import { store } from '../state/store';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  media: {
    height: 160,
  },
  main: {
    backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const sections = [
  {
    title: 'Tu Cita', url: '#', dispatch: 'set-page', value: 'Apointment', icon: Today,
  },
  {
    title: 'Regístrate', url: '#', dispatch: 'set-page', value: 'Registro', icon: People,
  },
  {
    title: 'Ubicaciones', url: '#', dispatch: 'set-page', value: 'PlacesSimple', icon: Apartment,
  },
  {
    title: 'Codigo QR', url: '#', dispatch: 'set-dialogQR', value: true, icon: BorderClear,
  },
  {
    title: 'Aviso De Privacidad', url: '#', dispatch: 'set-page', value: 'AvisoPrivacidad', icon: NavigateBefore,
  },
  {
    title: 'Disclaimer', url: '#', dispatch: 'set-page', value: 'Disclaimer', icon: NavigateBefore,
  },
  {
    title: 'Terminos De Uso', url: '#', dispatch: 'set-page', value: 'TerminosDeUso', icon: NavigateBefore,
  },
];
const sectionsPowerUser = [
  {
    title: 'Citas', url: '#', dispatch: 'set-page', value: 'Citas', icon: Today,
  },
  {
    title: 'Power Users', url: '#', dispatch: 'set-page', value: 'PowerUsers', icon: People,
  },
  {
    title: 'Reportes', url: '#', dispatch: 'set-page', value: 'Reporting', icon: Apartment,
  },
  {
    title: 'Escanear QR', url: '#', dispatch: 'set-dialogQRScanner', value: true, icon: BorderClear,
  },
  {
    title: 'Ubicaciones', url: '#', dispatch: 'set-page', value: 'Places', icon: Apartment,
  },
  {
    title: 'Aviso De Privacidad', url: '#', dispatch: 'set-page', value: 'AvisoPrivacidad', icon: NavigateBefore,
  },
  {
    title: 'Disclaimer', url: '#', dispatch: 'set-page', value: 'Disclaimer', icon: NavigateBefore,
  },
  {
    title: 'Terminos De Uso', url: '#', dispatch: 'set-page', value: 'TerminosDeUso', icon: NavigateBefore,
  },
];
function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}
function Main(props) {
  const classes = useStyles();

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { pacientes } = state;

  const [newUser, setNewUser] = useLocalStorage('newUser', 'true');

  useEffect(() => {
    async function getUserAtt() {
      console.log(await Auth.currentAuthenticatedUser());
      console.log('###VER_2.0###');
      const { attributes, signInUserSession } = await Auth.currentAuthenticatedUser();
      const { email } = attributes;
      const { accessToken } = signInUserSession;
      const groups = accessToken?.payload?.['cognito:groups'];
      const query = new URLSearchParams(window.location.search);
      const citaId = query.get('citaId');
      dispatch({
        type: 'set-groups',
        value: groups,
      });
      dispatch({
        type: 'set-accountEmail',
        value: email,
      });
      dispatch({
        type: 'set-user',
        value: attributes,
      });
      if (groups) {
        if (groups.includes('powerUsers') || groups.includes('Administradores')) {
          dispatch({
            type: 'set-page',
            value: 'PowerUserHome', // PowerUserHome
          });

          const accountEmail = query.get('accountEmail');
          if (citaId && accountEmail) {
            setTimeout(() => {
              dispatch({
                type: 'set-citasActiveInfo',
                value: {
                  accountEmail,
                  citaId,
                },
              });
              dispatch({
                type: 'set-dialogCitasActive',
                value: true,
              });
            }, 500);
          }
        } else {
          dispatch({
            type: 'set-page',
            value: 'Home', // Home
          });
          if (citaId) {
            setTimeout(() => {
              dispatch({
                type: 'set-payCitaId',
                value: citaId,
              });
              dispatch({
                type: 'set-dialogPayCita',
                value: true,
              });
            }, 500);
          }
        }
      } else {
        dispatch({
          type: 'set-page',
          value: 'Home', // Home
        });
        if (citaId) {
          setTimeout(() => {
            dispatch({
              type: 'set-payCitaId',
              value: citaId,
            });
            dispatch({
              type: 'set-dialogPayCita',
              value: true,
            });
          }, 500);
        }
      }
    }
    getUserAtt();
  }, []);

  useEffect(() => {}, []);
  return (
    <>
      <CssBaseline />
      <DialogNewUser dialog={newUser === 'true'} setDialog={() => setNewUser(false)} />
      {
        state.dialogPayCita ? <DialogPayCita /> : null
      }
      {
        state.dialogCitasActive ? <DialogCitasActive /> : null
      }
      {
        state.dialogQR ? <DialogQR /> : null
      }
      {
        state.dialogQRScanner ? <DialogQRScanner /> : null
      }
      <Notification />
      <Container disableGutters>
        <Header title="Blog" sections={state.groups ? state.groups.includes('powerUsers') || state.groups.includes('Administradores') ? sectionsPowerUser : sections : sections} />
        <main>
          {
          state.page === 'Reporting' ? <Reporting /> : null
          }
          {
          state.page === 'Citas' ? <Citas /> : null
          }
          {
          state.page === 'PowerUsers' ? <PowerUsers /> : null
          }
          {
          state.page === 'PowerUserHome' ? <PowerUserHome /> : null
          }
          {
          state.page === 'Home' ? <Home /> : null
          }
          {
          state.page === 'Places' ? <Places /> : null
          }
          {
          state.page === 'PlacesSimple' ? <PlacesSimple /> : null
          }
          {
          state.page === 'Apointment' ? <Apointment /> : null
          }
          {
          state.page === 'Registro' ? <Registro /> : null
          }
          {
          state.page === 'AvisoPrivacidad' ? <AvisoPrivacidad /> : null
          }
          {
          state.page === 'Disclaimer' ? <Disclaimer /> : null
          }
          {
          state.page === 'TerminosDeUso' ? <TerminosDeUso /> : null
          }
        </main>
      </Container>
      <Footer />
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>

    </>
  );
}
const signUpConfig = {
  signUpFields: [
    {
      label: 'Nombre',
      key: 'given_name',
      required: true,
      displayOrder: 1,
      type: 'text',
      intl: {
        label: 'signUp.labels.given_name',
      },
    },
    {
      label: 'Apellido',
      key: 'family_name',
      required: true,
      displayOrder: 2,
      type: 'text',
      intl: {
        label: 'signUp.labels.family_name',
      },
    },
    {
      label: 'Correo electrónico',
      key: 'email',
      required: true,
      displayOrder: 3,
      type: 'email',
    },
    {
      label: 'Teléfono Celular',
      key: 'custom:cellphone',
      required: true,
      displayOrder: 4,
      type: 'text',
      intl: {
        label: 'signUp.labels.cellphone',
      },
    },
    {
      label: 'Contraseña',
      key: 'password',
      required: true,
      displayOrder: 5,
      type: 'password',
    },
  ],
};
export default withAuthenticator(Main, {
  hide: [Greetings],
  hideEmail: true,
  signUpConfig,
  hideSignUpEmail: true,
  intlProps: {
    supportedLocales: ['es'],
    customMessages: {
      es: {
        requireNewPassword: {
          links: {
            backToSignIn: 'Regresa al inicio de sesión',
          },
          buttons: {
            change: 'Cambiar',
          },
          header: 'Cambio de contraseña',
        },
        confirmSignUp: {
          header: 'Confirma la creacion de cuenta',
          links: {
            backToSignIn: 'Regresa al inicio de sesión',
            resendCode: 'Enviar de nuevo el código',
          },
          buttons: {
            confirm: 'Confirmar',
          },
        },
        signUp: {
          header: 'Crear una cuenta nueva',
          buttons: {
            create: 'Crear',
          },
          links: {
            signIn: 'Iniciar sesión',
          },
          text: {
            haveAccount: 'Ya tienes una cuenta',
          },
          labels: {
            family_name: 'Apellido',
            given_name: 'Nombre',
            cellphone: 'Teléfono celular',
            email: 'Correo electrónico',
          },
          errors: {
            UsernameExistsException: 'Ya existe una cuenta con el correo electrónico proporcionado.',
          },
        },
        forgotPassword: {
          header: '¿Olvidaste tu contraseña?',
          buttons: {
            sendCode: 'Enviar código',
          },
        },
        signIn: {
          links: {
            forgotPassword: '¿Olvidaste tu contraseña?',
            signUp: 'Crear una cuenta',
            header: 'Inicio de sesión',
            backToSignIn: 'Regresar',
            signIn: 'Crear cuenta',
          },
          buttons: {
            signIn: 'Iniciar sesión',
          },
          header: 'Inicio de sesión',
          errors: {
            UserNotFoundException: 'La cuenta no existe',
          },
        },
        global: {
          labels: {
            username: 'Correo electronico',
            password: 'Contraseña',
            forgotPassword: '¿Olvidaste tu contraseña?',
            signUp: 'Crear cuenta',
            email: 'Correo electronico',
            confirmationCode: 'Código de confirmación',
            newPassword: 'Nueva contraseña',
          },
        },
      },
    },
  },
});

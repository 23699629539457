/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  Grow,
  Fade,
  Paper,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Typography,
  Toolbar,
  IconButton,
  Link,
} from '@material-ui/core';
import { QRCode } from 'react-qr-svg';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import clsx from 'clsx';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import esLocale from 'dayjs/locale/es';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  PhotoCamera, Cancel, Close, HighlightOff,
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components
import CardPaciente from './CardPaciente';
import Markdown from './Markdown';
// Markdown
import terminosText from '../markdown/TerminosDeUso';
import privacidadText from '../markdown/AvisoDePrivacidad';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
}));
const sexChoices = [{
  value: 'masculino',
  label: 'Masculino',
},
{
  value: 'femenino',
  label: 'Femenino',
}];

function getSteps() {
  return ['Información Personal', 'Vacunas', 'Foto'];
}

function DialogNewUser(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Form Vars
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [sex, setSex] = useState('');
  const [dob, setDOB] = useState();
  const [image, setImage] = useState('');
  const [birdPlace, setBirdPlace] = useState('');
  const [phone, setPhone] = useState('');

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // Error Form
  const [nameError, setNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [sexError, setSexError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [birdPlaceError, setBirdPlaceError] = useState(false);
  const [imageError, setImageError] = useState(false);
  // CheckBox
  const [terminosDeUso, setTerminosDeUso] = useState(false);
  const [terminosDialog, setTerminosDialog] = useState(false);
  const [privacidad, setPrivacidad] = useState(false);
  const [privacidadDialog, setPrivacidadDialog] = useState(false);

  const [alert, setAlert] = useState(false);

  // Check Box
  const [vacunas, setVacunas] = useState([]);
  const [alergias, setAlergias] = useState('');

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const verifyAgainst = (e, value, setError) => {
    setError(e === value);
    return e === value;
  };

  const clearForm = () => {
    setName('');
    setLastName('');
    setSex('');
    setDOB(dayjs());
    setImage('');
    setPhone('');
    setBirdPlace('');
    setVacunas([]);
    setAlergias('');
    setLoading(false);
    setSuccess(false);
    setNameError(false);
    setLastNameError(false);
    setSexError(false);
    setDobError(false);
    setPhoneError(false);
    setBirdPlaceError(false);
    setActiveStep(0);
  };

  const getProps = () => {
    const r = {
      name,
      lastName,
      sex,
      dob: dob.format('DD/MM/YYYY'),
      image,
      edit: false,
      primary: false,
    };
    return r;
  };
  useEffect(() => {
    console.log(props);
  }, []);

  return (
    <>
      <Dialog
        maxWidth="md"
        open={terminosDialog}
        onClose={() => setTerminosDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Términos de Uso
        </DialogTitle>
        <DialogContent>
          <Markdown className={classes.markdown}>
            {terminosText}
          </Markdown>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setTerminosDialog(false)}
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        open={privacidadDialog}
        onClose={() => setPrivacidadDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Política de Privacidad
        </DialogTitle>
        <DialogContent>
          <Markdown className={classes.markdown}>
            {privacidadText}
          </Markdown>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPrivacidadDialog(false)}
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={props.dialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Bienvenido
        </DialogTitle>
        <DialogContent>
          {alert ? (
            <Paper elevation={0} style={{ backgroundColor: '#f5abab' }}>
              <Typography variant="button" display="block" gutterBottom>
                Da click en la caja y presiona el botón de  Aceptar para continuar.
              </Typography>
            </Paper>
          ) : null}
          <Checkbox checked={terminosDeUso} onChange={(e) => setTerminosDeUso(e.target.checked)} name="checkedA" />
          <Typography
            display="inline"
            align="justify"
          >
            Si, he leído y presto mi consentimiento a los
            {' '}
            <Link href="#" onClick={() => setTerminosDialog(true)}>
              Términos de Uso
            </Link>
            {' '}
            del sitio y al procesamiento, al tratamiento y a la transferencia de mis datos personales conforme a lo dispuesto en la
            {' '}
            <Link href="#" onClick={() => setPrivacidadDialog(true)}>
              Política de Privacidad
            </Link>
          </Typography>
        </DialogContent>
        <DialogActions>

          <Button
            onClick={() => {
              if (terminosDeUso) {
                props.setDialog();
              } else {
                console.log(121);
                setAlert(true);
              }
            }}
            color="primary"
          >
            Aceptar
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogNewUser;

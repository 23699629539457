/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  Tooltip,
  ListItem,
  Button, ListItemText, ListItemIcon, Link, Typography, IconButton, Toolbar,
} from '@material-ui/core';
import { Menu, Close, ArrowBack } from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import { Auth } from 'aws-amplify';
// Store
import { store } from '../state/store';
// Logo
import logo from '../svg/logo_4_.svg';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    // justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  logo: {
    height: 30,
  },
  fullList: {
    width: 'auto',
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const { sections, title } = props;
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const [drawer, setDrawer] = useState(false);

  return (
    <>
      <Drawer anchor="top" open={drawer} onClose={() => setDrawer(false)}>
        <div
          className={classes.fullList}
          role="presentation"
          onClick={() => setDrawer(false)}
          onKeyDown={() => setDrawer(false)}
        >
          <List>
            {sections.map((section) => (
              <ListItem
                button
                key={section.title}
                onClick={() => {
                  dispatch({
                    type: section.dispatch,
                    value: section.value,
                  });
                }}
              >
                <ListItemIcon><section.icon /></ListItemIcon>
                <ListItemText primary={section.title} />
              </ListItem>
            ))}
            <ListItem
              button
              key="Salir"
              onClick={() => Auth.signOut()}
            >
              <ListItemIcon><Close /></ListItemIcon>
              <ListItemText primary="Salir" />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <Toolbar className={classes.toolbar} id="back-to-top-anchor">
        {/* <Button size="small">Subscribe</Button> */}
        {
  state.page === 'Home' || state.page === 'PowerUserHome'
    ? (
      <Button onClick={() => {
        if (state.groups) {
          if (state.groups.includes('powerUsers') || state.groups.includes('Administradores')) {
            dispatch({
              type: 'set-page',
              value: 'PowerUserHome',
            });
          } else {
            dispatch({
              type: 'set-page',
              value: 'Home',
            });
          }
        } else {
          dispatch({
            type: 'set-page',
            value: 'Home',
          });
        }
      }}
      >
        <img src={logo} className={classes.logo} alt="logo" />
      </Button>
    ) : (
      <IconButton
        aria-label="Salir"
        onClick={() => {
          if (state.groups) {
            if (state.groups.includes('powerUsers') || state.groups.includes('Administradores')) {
              dispatch({
                type: 'set-page',
                value: 'PowerUserHome',
              });
            } else {
              dispatch({
                type: 'set-page',
                value: 'Home',
              });
            }
          } else {
            dispatch({
              type: 'set-page',
              value: 'Home',
            });
          }
        }}
      >
        <ArrowBack />
      </IconButton>
    )
}

        {!isMobile ? (
          <Button onClick={() => {
            if (state.groups) {
              if (state.groups.includes('powerUsers') || state.groups.includes('Administradores')) {
                dispatch({
                  type: 'set-page',
                  value: 'PowerUserHome',
                });
              } else {
                dispatch({
                  type: 'set-page',
                  value: 'Home',
                });
              }
            } else {
              dispatch({
                type: 'set-page',
                value: 'Home',
              });
            }
          }}
          >
            <>
              <Typography
                variant="h5"
                color="inherit"
                noWrap
              >
                PrivateHC
              </Typography>
              {' '}
              <Typography
                style={{ marginLeft: 10, marginTop: 10 }}
                variant="subtitle2"
                color="inherit"
                noWrap
              >
                Membresía
              </Typography>
            </>

          </Button>
        ) : null}
        <Button
          className={classes.toolbarTitle}
          onClick={() => {
            if (state.groups) {
              if (state.groups.includes('powerUsers') || state.groups.includes('Administradores')) {
                dispatch({
                  type: 'set-page',
                  value: 'PowerUserHome',
                });
              } else {
                dispatch({
                  type: 'set-page',
                  value: 'Home',
                });
              }
            } else {
              dispatch({
                type: 'set-page',
                value: 'Home',
              });
            }
          }}
        >
          <Typography
            component="h6"
            variant="h6"
            color="inherit"
            align={isMobile ? 'left' : 'center'}
          // align="center"
            noWrap
          >

            {state.title}

          </Typography>
        </Button>
        {
          isMobile
            ? (
              <IconButton onClick={() => setDrawer(true)}>
                <Menu />
              </IconButton>
            ) : null
        }
        {
          !isMobile
            ? (
              <Tooltip title="Salir" aria-label="Salir">
                <IconButton aria-label="Salir" onClick={() => Auth.signOut()}>
                  <Close />
                </IconButton>
              </Tooltip>
            ) : null
        }

      </Toolbar>
      {
        !isMobile
          ? (
            <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
              {sections.map((section) => (
                <Link
                  color="inherit"
                  noWrap
                  key={section.title}
                  variant="body2"
                  href={section.url}
                  onClick={() => {
                    dispatch({
                      type: section.dispatch,
                      value: section.value,
                    });
                  }}
                  className={classes.toolbarLink}
                >
                  {section.title}
                </Link>
              ))}
            </Toolbar>
          ) : null
      }
    </>
  );
}

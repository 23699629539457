/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef, forwardRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  LinearProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Chip,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Fade,
  Paper,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import Client from 'shopify-buy';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import MaterialTable from 'material-table';
import {
  Close,
  Room,
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Refresh,
  Email,
  Add,
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components

const client = Client.buildClient({
  storefrontAccessToken: 'db49f66623fa938339b44ae88554da62',
  domain: 'private-health-club.myshopify.com',
  language: 'es-MX',
});

const messageUrl = ' https://www.privatehc.com?page=citas';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function getSteps() {
  return ['Productos Disponibles', 'Mensaje'];
}
function DialogPowerUserCreateCita(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Citas
  const [valid, setValid] = useState([]);
  const [all, setAll] = useState([]);
  const [showSelect, setShowSelect] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productsError, setProductsError] = useState(false);

  // SNS
  const [msg, setMsg] = useState('');
  const [msgError, setMsgError] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [sendSMS, setSendSMS] = useState(true);
  const [chars, setChars] = useState(0);

  // Shopify
  const [products, setProducts] = useState([]);
  // Dialog
  const [dialogProducts, setDialogProducts] = useState(false);
  // Steper
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const verifyAgainst = (e, value, setError) => {
    setError(e === value);
    return e === value;
  };
  const verifyValues = () => {
    switch (activeStep) {
      case 0:
        // eslint-disable-next-line no-unused-expressions
        [
          verifyAgainst(selectedProducts.length, 0, setProductsError),
        ].filter((e) => e === true).length ? '' : handleNext();
        break;
      case 1:
        // eslint-disable-next-line no-unused-expressions
        [
          verifyAgainst(chars > 123, true, setMsgError),
          verifyAgainst(msg, '', setMsgError),
        ].filter((e) => e === true).length ? '' : handleNext();
        break;
      default:
        handleNext();
        console.log('INVALID');
    }
  };

  // eslint-disable-next-line max-len
  const getAdditionalData = async (merge, lastEvaluatedKey, Items) => {
    dispatch({
      type: 'set-loading',
      value: true,
    });
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/reports',
      headers: { Authorization: token },
      params: {
        from: 0,
        to: 0,
        table: merge[merge.length - 1].table,
        key: merge[merge.length - 1].key,
        values: merge[merge.length - 1].values,
        scan: 'scan',
        lastEvaluatedKey: JSON.stringify(lastEvaluatedKey),
      },
    }).then((res) => res.data).catch((err) => err);
    console.log(result.Items.length);
    let res = Items.concat(result.Items);
    // eslint-disable-next-line no-prototype-builtins
    if (result.hasOwnProperty('LastEvaluatedKey')) {
      if (merge[merge.length - 1].table !== 'users') {
        res = Items.map((e) => {
          result.Items.forEach((i) => {
            if (e.accountEmail === i.accountEmail) {
              // eslint-disable-next-line no-prototype-builtins
              if (e.hasOwnProperty(merge[merge.length - 1].table)) {
                e[merge[merge.length - 1].table].push(i);
              } else {
                e[merge[merge.length - 1].table] = [i];
              }
            }
          });
          return e;
        });
      } else {
        res = Items.concat(res);
      }
      getAdditionalData(merge, result.LastEvaluatedKey, res);
    } else {
      const pop = merge.pop();
      if (merge.length) {
        if (pop.table === 'users') {
          getData(merge, res);
        } else {
          res = Items.map((e) => {
            result.Items.forEach((i) => {
              if (e.accountEmail === i.accountEmail) {
                // eslint-disable-next-line no-prototype-builtins
                if (e.hasOwnProperty(pop.table)) {
                  e[pop.table].push(i);
                }
                e[pop.table] = [i];
              }
            });
            return e;
          });
          getData(merge, res);
        }
      } else {
        res = Items.map((e) => {
          result.Items.forEach((i) => {
            if (e.accountEmail === i.accountEmail) {
              // eslint-disable-next-line no-prototype-builtins
              if (e.hasOwnProperty(pop.table)) {
                e[pop.table].push(i);
              } else {
                e[pop.table] = [i];
              }
            }
          });
          return e;
        });
        console.log(res);
        dispatch({
          type: 'set-newCitasTable',
          value: res,
        });
        dispatch({
          type: 'set-notificationMessage',
          value: 'Datos Actualizados',
        });
        dispatch({
          type: 'set-notification',
          value: true,
        });
        setLoading(false);
        setSuccess(true);
        dispatch({
          type: 'set-loading',
          value: false,
        });
      }
    }
  };
  const getData = async (merge, Items = []) => {
    dispatch({
      type: 'set-loading',
      value: true,
    });
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/reports',
      headers: { Authorization: token },
      params: {
        from: 0,
        to: 0,
        table: merge[merge.length - 1].table,
        key: merge[merge.length - 1].key,
        values: merge[merge.length - 1].values,
        scan: 'scan',
      },
    }).then((res) => res.data).catch((err) => err);
    let res = result.Items;
    // eslint-disable-next-line no-prototype-builtins
    if (result.hasOwnProperty('LastEvaluatedKey')) {
      if (merge[merge.length - 1].table !== 'users') {
        res = Items.map((e) => {
          result.Items.forEach((i) => {
            if (e.accountEmail === i.accountEmail) {
              // eslint-disable-next-line no-prototype-builtins
              if (e.hasOwnProperty(merge[merge.length - 1].table)) {
                e[merge[merge.length - 1].table].push(i);
              }
              e[merge[merge.length - 1].table] = [i];
            }
          });
          return e;
        });
      }
      getAdditionalData(merge, result.LastEvaluatedKey, res);
    } else {
      const pop = merge.pop();
      if (merge.length) {
        if (pop.table === 'users') {
          getData(merge, res);
        } else {
          res = Items.map((e) => {
            result.Items.forEach((i) => {
              if (e.accountEmail === i.accountEmail) {
                // eslint-disable-next-line no-prototype-builtins
                if (e.hasOwnProperty(pop.table)) {
                  e[pop.table].push(i);
                }
                e[pop.table] = [i];
              }
            });
            return e;
          });
          getData(merge, res);
        }
      } else {
        res = Items.map((e) => {
          result.Items.forEach((i) => {
            if (e.accountEmail === i.accountEmail) {
              // eslint-disable-next-line no-prototype-builtins
              if (e.hasOwnProperty(pop.table)) {
                e[pop.table].push(i);
              }
              e[pop.table] = [i];
            }
          });
          return e;
        });
        dispatch({
          type: 'set-newCitasTable',
          value: res,
        });
        dispatch({
          type: 'set-notificationMessage',
          value: 'Datos Actualizados',
        });
        dispatch({
          type: 'set-notification',
          value: true,
        });
        setLoading(false);
        setSuccess(true);
        dispatch({
          type: 'set-loading',
          value: false,
        });
      }
    }
  };
  const getProducts = () => {
    client.product.fetchAll().then((res) => {
      console.log('##PRODUCTS##');
      console.log(res);
      setProducts(res);
      const p = res.filter((e) => e?.availableForSale).filter((e) => e?.productType === 'Membresía');
      if (p.length) {
        setSelectedProducts([p[0]]);
      }
    });
  };
  const getAllData = async () => {
    dispatch({
      type: 'set-loading',
      value: true,
    });
    await getData([
      {
        table: 'pacientes',
        key: 'pacienteId',
        values: 'accountEmail,pacienteId,name,lastName',
      },
      {
        table: 'citas',
        key: 'citaId',
        values: 'accountEmail,citaId,cita,created,updated,pago,status,servicio,pacientes',
      },
      {
        table: 'users',
        key: 'createdDate',
        values: 'accountEmail,cellphone,createdDate,lastName,name',
      },
    ]);
  };
  const uploadData = async (data) => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'post',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/contacto/list',
      headers: { Authorization: token },
      data,
    }).then((res) => res.data).catch((err) => err);
    console.log(result);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Mensajes Enviados',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    setLoading(false);
    setSuccess(true);
    setDialogProducts(false);
    clearForm();
    // dispatch({
    //   type: 'set-dialogNewPatient',
    //   value: false,
    // });
  };
  const clearForm = () => {
    setLoading(false);
    setSuccess(false);
    setShowSelect(false);
    setMsg('');
    setMsgError(false);
    setSelectedProducts([]);
    setProductsError(false);
    setChars(0);
    setActiveStep(0);
  };
  const setSelected = (e) => {
    setValid([e]);
    setShowSelect(false);
  };
  useEffect(() => {
    console.log('##################dialogPowerUserCreateCita');
    clearForm();
    getAllData();
    getProducts();
  }, []);

  return (
    <>
      <Dialog
        open={dialogProducts}
        onClose={() => {
          clearForm();
          setDialogProducts(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="products-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              Iniciar Nueva Cita
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  clearForm();
                  setDialogProducts(false);
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>

        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {
          activeStep === 0 ? (
            <>
              <Fade in={activeStep === 0}>
                <Paper elevation={0}>
                  <DialogContentText>
                    Seleccione uno o mas servicios disponibles.
                  </DialogContentText>
                  {
                        productsError ? (
                          <Typography variant="body1" gutterBottom color="secondary" style={{ marginTop: 5 }}>
                            ⚠️Seleccionar un producto, para completar este paso.
                          </Typography>
                        ) : null
                      }
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} sm={12} md={12} style={{ marginTop: 5 }}>
                      <Typography variant="button" gutterBottom style={{ fontWeight: 'bold' }}>
                        Productos
                      </Typography>
                    </Grid>
                    {
                      products.filter((e) => e?.availableForSale).filter((e) => e?.productType === 'Membresía').map((p) => (
                        <Grid item xs={12} sm={6} md={6} key={`product-${p?.id}`}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={selectedProducts.filter(
                                  (f) => f.id === p.id,
                                ).length}
                                onChange={() => {
                                  if (selectedProducts.filter((f) => f.id === p.id).length) {
                                    setSelectedProducts(selectedProducts.filter(
                                      (e) => e.id !== p.id,
                                    ));
                                  } else {
                                    const vTemp = [...selectedProducts];
                                    vTemp.push(p);
                                    setSelectedProducts(vTemp);
                                    setProductsError(false);
                                    // setSelectedProducts(v.vacunaCode);
                                    // setConfirmDate(true);
                                  }
                                }}
                                name={`check-${p?.id}`}
                                color="secondary"
                              />
                          )}
                            label={p?.title}
                          />
                        </Grid>
                      ))
                    }
                    {/* <Grid item xs={12} sm={12} md={12} style={{ marginTop: 5 }}>
                      <Typography variant="button" gutterBottom style={{ fontWeight: 'bold' }}>
                        Vacunas
                      </Typography>
                    </Grid>
                    {
                      products.filter((e) => e?.availableForSale)
                      .filter((e) => e?.productType === 'Vacunas').map((p) => (
                        <Grid item xs={12} sm={6} md={6} key={`product-${p?.id}`}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={selectedProducts.filter(
                                  (f) => f.id === p.id,
                                ).length}
                                onChange={() => {
                                  if (selectedProducts.filter((f) => f.id === p.id).length) {
                                    setSelectedProducts(selectedProducts.filter(
                                      (e) => e.id !== p.id,
                                    ));
                                  } else {
                                    const vTemp = [...selectedProducts];
                                    vTemp.push(p);
                                    setSelectedProducts(vTemp);
                                    setProductsError(false);
                                  }
                                }}
                                name={`check-${p?.id}`}
                                color="secondary"
                              />
                          )}
                            label={p?.title}
                          />
                        </Grid>
                      ))
                    } */}
                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}

          {
          activeStep === 1 ? (
            <>
              <Fade in={activeStep === 1}>
                <Paper elevation={0}>
                  {sendSMS ? (
                    <DialogContentText>
                      Los mensajes de texto SMS están limitados a 123 caracteres.
                      <Chip label={`${chars} Caracteres Usados`} variant="outlined" />
                    </DialogContentText>
                  ) : null}
                  {
                        productsError ? (
                          <Typography variant="body1" gutterBottom color="secondary" style={{ marginTop: 5 }}>
                            ⚠️Seleccionar un producto, para completar este paso.
                          </Typography>
                        ) : null
                      }
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControlLabel
                        control={<Checkbox checked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)} name="checkedA" />}
                        label="Correo Electrónico"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControlLabel
                        control={<Checkbox checked={sendSMS} onChange={(e) => setSendSMS(e.target.checked)} name="checkedA" />}
                        label="Mensaje SMS"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        required
                        multiline
                        rows={3}
                        margin="dense"
                        error={msgError}
                        id="message"
                        label="Mensaje"
                        type="text"
                        fullWidth
                        value={msg}
                        onChange={(e) => {
                          setChars(e.target.value.length);
                          if (chars > 123) {
                            setMsgError(true);
                          } else {
                            setMsgError(false);
                          }
                          setMsg(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}

          {
activeStep === steps.length ? (
  <>
    <Fade in={activeStep === steps.length}>
      <Paper elevation={0}>
        <DialogContentText>
          Vista previa de los productos seleccionados y el mensaje a enviar.
        </DialogContentText>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} sm={12} md={12} style={{ marginTop: 5 }}>
            <Typography variant="button" gutterBottom style={{ fontWeight: 'bold' }}>
              Productos
            </Typography>
          </Grid>
          {
                      selectedProducts.map((p) => (
                        <Grid item xs={12} sm={6} md={6} key={`product-${p?.id}`}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={selectedProducts.filter(
                                  (f) => f.id === p.id,
                                ).length}
                                name={`check-${p?.id}`}
                                color="secondary"
                              />
                          )}
                            label={p?.title}
                          />
                        </Grid>
                      ))
                    }
          <Grid item xs={12} sm={12} md={12} style={{ marginTop: 5 }}>
            <Typography variant="button" gutterBottom style={{ fontWeight: 'bold' }}>
              Mensaje
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              multiline
              rows={3}
              margin="dense"
              error={msgError}
              id="message"
              type="text"
              variant="filled"
              fullWidth
              value={msg}
            />
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  </>
) : null
}

        </DialogContent>
        <DialogActions>
          {activeStep === steps.length ? (
            <>
              <Button
                onClick={() => {
                  clearForm();
                  setDialogProducts(false);
                }}
              >
                Cancelar
              </Button>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    console.log(selectedUsers);
                    uploadData({
                      msg,
                      sendEmail,
                      sendSMS,
                      countryCode: '+52',
                      users: selectedUsers.map((e) => ({
                        accountEmail: e.accountEmail,
                        cellphone: e.cellphone,
                      })),
                      products: selectedProducts.map((e) => ({
                        id: e.id,
                        title: e.title,
                        productType: e.productType,
                      })),
                    });
                  }}
                >
                  Enviar
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </>
          ) : (
            <>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Regresar
              </Button>
              <Button
                onClick={verifyValues}
                color="primary"
              >
                Siguiente
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={isMobile}
        fullWidth={!isMobile}
        maxWidth="xl"
        open={state.dialogPowerUserCreateCita}
        onClose={() => {
          dispatch({
            type: 'set-dialogPowerUserCreateCita',
            value: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              Crear Cita
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogPowerUserCreateCita',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {state?.loading ? <LinearProgress /> : null}
          <MaterialTable
            icons={tableIcons}
            localization={{
              pagination: {
                firstAriaLabel: 'Primera página',
                firstTooltip: 'Primera página',
                previousAriaLabel: 'Pagina anterior',
                previousTooltip: 'Pagina anterior',
                nextAriaLabel: 'Siguiente página',
                nextTooltip: 'Siguiente página',
                lastAriaLabel: 'Última página',
                lastTooltip: 'Última página',
                labelDisplayedRows: '{from}-{to} de {count}',
                labelRowsSelect: 'Filas',
                labelRowsPerPage: 'Filas por página:',
              },
              toolbar: {
                nRowsSelected: '{0} fila(s) seleccionadas',
                searchTooltip: 'Buscar',
                searchPlaceholder: 'Buscar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportName: 'Exportar como CSV',
                exportCSVName: 'Exportar como CSV',
                exportPDFName: 'Exportar como PDF',
              },
              header: {
                actions: 'Aciones',
              },
              body: {
                emptyDataSourceMessage: 'No hay registros que mostrar',
                filterRow: {
                  filterTooltip: 'Filtrar',
                },
              },
            }}
            options={{
              pageSizeOptions: [10, 50, 100, 1000],
              exportButton: true,
              selection: true,
            }}
            onSelectionChange={(rows) => {
              setSelectedUsers(rows);
            }}
            columns={[
              { title: 'Cuenta', field: 'accountEmail' },
              {
                title: 'Creada',
                field: 'createdDate',
                render: (rowData) => dayjs(rowData?.createdDate || dayjs().valueOf()).format('DD/MM/YYYY hh:mm A'),
                type: 'datetime',
              },
              { title: 'Nombres', field: 'name' },
              { title: 'Apellidos', field: 'lastName' },
              {
                title: 'Citas',
                field: 'citas',
                render: (rowData) => (rowData?.citas ? rowData?.citas.length : 0),
                type: 'number',
              },
              {
                title: 'Pagos',
                field: 'citas',
                render: (rowData) => (rowData?.citas
                  ? rowData?.citas.filter((e) => e.pago === 1).length
                  : 0),
                type: 'number',
              },
              {
                title: 'Citas Completadas',
                field: 'citas',
                render: (rowData) => (rowData?.citas
                  ? rowData?.citas.filter((e) => e.status === 1).length
                  : 0),
                type: 'number',
              },
              {
                title: 'Ultima Cita',
                field: 'citas',
                render: (rowData) => (rowData?.citas
                  ? dayjs(rowData?.citas.sort((a, b) => b.citaId - a.citaId)[0].citaId).format('DD/MM/YYYY hh:mm A')
                  : ''),
                type: 'datetime',
              },
              {
                title: 'Ultima Cita Pacientes',
                field: 'citas',
                render: (rowData) => (rowData?.citas
                  ? rowData?.citas.sort((a, b) => b.citaId - a.citaId)[0].pacientes.length
                  : ''),
                type: 'number',
              },
              {
                title: 'Ultimo Servicio',
                field: 'citas',
                render: (rowData) => (rowData?.citas
                  ? rowData?.citas.sort((a, b) => b.citaId - a.citaId)[0].servicio
                  : ''),
              },
              {
                title: 'Pacientes',
                field: 'pacientes',
                render: (rowData) => (rowData?.pacientes ? rowData?.pacientes.length : 0),
                type: 'number',
              },
            ]}
            data={state?.newCitasTable}
            title="Usuarios, Pacientes y Citas"
            actions={[
              {
                icon: Edit,
                tooltip: 'Editar',
                disabled: selectedUsers.length > 1,
                onClick: (event, rowData) => {
                  dispatch({
                    type: 'set-dialogPowerUserCita',
                    value: true,
                  });
                  dispatch({
                    type: 'set-editCitaInfo',
                    value: rowData[0],
                  });
                // alert(`You saved ${rowData.citaId}`);
                },
              },
              {
                icon: Email,
                tooltip: 'Contactar',
                disabled: selectedUsers.length > 1,
                onClick: (event, rowData) => {
                  dispatch({
                    type: 'set-dialogContact',
                    value: true,
                  });
                  dispatch({
                    type: 'set-editContact',
                    value: {
                      email: rowData[0].accountEmail,
                      phone: rowData[0].cellphone,
                    },
                  });
                // alert(`You saved ${rowData.citaId}`);
                },
              },
              {
                tooltip: 'Crear Cita',
                icon: Add,
                // eslint-disable-next-line no-alert
                onClick: (evt, data) => {
                  console.log(data);
                  setSelectedUsers(data);
                  setDialogProducts(true);
                  // alert(`You want to delete ${data.length} rows`)
                },
              },
            ]}
          />
        </DialogContent>
        <DialogActions>

          <Button
            onClick={() => {
              dispatch({
                type: 'set-dialogPowerUserCreateCita',
                value: false,
              });
            }}
            color="primary"
          >
            Cerrar
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogPowerUserCreateCita;

/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  Fab,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardActionArea,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  Chip,
  Checkbox,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import dayjs from 'dayjs';
// Store
import { store } from '../state/store';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 200,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function CardTotal(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  const [open, setOpen] = useState(false);

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5">
            Mi Carrito
          </Typography>
          <List className={classes.root}>
            <ListItem>
              <ListItemText primary="Servicio" secondary={props.servicio} />
              <ListItemSecondaryAction>
                <Typography syle={{ fontWeight: 'bold' }} color="primary">
                  X
                  {' '}
                  {props.selected.length}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Total" />
              <ListItemSecondaryAction>
                <Typography syle={{ fontWeight: 'bolder' }} color="primary">
                  $
                  {' '}
                  {props.total}
                </Typography>
              </ListItemSecondaryAction>

            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  );
}

export default CardTotal;

/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  Fab,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardActionArea,
  CardMedia,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import axios from 'axios';
// Components
import AppBar from '../components/AppBar';
import CardPaciente from '../components/CardPaciente';
import DialogNewPaciente from '../components/DialogNewPaciente';
import DialogUpdatePaciente from '../components/DialogUpdatePaciente';
import Markdown from '../components/Markdown';
// Markdown
import avisoText from '../markdown/AvisoDePrivacidad';
// Store
import { store } from '../state/store';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  media: {
    height: 160,
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
}));

function AvisoDePrivacidad() {
  const classes = useStyles();

  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  useEffect(() => {
    dispatch({
      type: 'set-title',
      value: 'Aviso De Privacidad',
    });
  }, []);

  return (
    <>
      <Container className={classes.cardGrid} maxWidth="lg">
        <Markdown className={classes.markdown}>
          {avisoText}
        </Markdown>
      </Container>
    </>
  );
}

export default AvisoDePrivacidad;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8a648a3a-82d5-444b-87c1-29f4fe1ab852",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Eqsrhx2hp",
    "aws_user_pools_web_client_id": "6kcd11hgh6eisf8nefa9vqn3fd",
    "oauth": {},
    "aws_user_files_s3_bucket": "vacunacion205124-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  Grow,
  Fade,
  Paper,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Typography,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  ListSubheader,
  Chip,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import {
  PhotoCamera, Cancel, Close, NavigateNext, Block, Check,
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components
import CardPaciente from './CardPaciente';
import DialogPowerUserPatient from './DialogPowerUserPatient';
import CardCita from './CardCita';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const sexChoices = [{
  value: 'masculino',
  label: 'Masculino',
},
{
  value: 'femenino',
  label: 'Femenino',
}];

function getSteps() {
  return ['Pacientes'];
}

function DialogCitasActive(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { citasActiveInfo, editCitaInfo } = state;

  // Form Vars
  const [dob, setDOB] = useState();
  const [place, setPlace] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [pago, setPago] = useState(1);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [created, setCreated] = useState('');

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // Error Form

  const [dobError, setDobError] = useState(false);
  const [placeError, setPlaceError] = useState(false);
  const [homeAddressError, setHomeAddressError] = useState(false);

  const [cita, setCita] = useState(false);
  const [dosis, setDosis] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [service, setService] = useState('');
  const [products, setProducts] = useState([]);
  const [placeChoices, setPlaceChoices] = useState([]);
  const [pacientes, setPacientes] = useState([]);
  const [status, setStatus] = useState(0);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const verifyAgainst = (e, value, setError) => {
    setError(e === value);
    return e === value;
  };
  const verifyValues = () => {
    const validations = [];
    switch (activeStep) {
      case 0:
        // eslint-disable-next-line no-unused-expressions
        if (cita) {
          validations.push(verifyAgainst(place, '', setPlaceError));
          if (place === 'home' || place === 'custom') {
            console.log('validate addresds');
            validations.push(verifyAgainst(homeAddress, '', setHomeAddressError));
          }
        }
        // eslint-disable-next-line no-unused-expressions
        validations.filter((e) => e).length ? '' : handleNext();
        // [
        //   verifyAgainst(name, '', setNameError),
        //   verifyAgainst(lastName, '', setLastNameError),
        //   verifyAgainst(sex, '', setSexError),
        //   verifyAgainst(dob.format('DD/MM/YYYY'), dayjs().format('DD/MM/YYYY'), setDobError),
        //   verifyAgainst(birdPlace, '', setBirdPlaceError),
        // ].filter((e) => e === true).length ? '' : handleNext();
        break;
      default:
        handleNext();
        console.log('INVALID');
    }
  };

  const getData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: `https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/citas/${citasActiveInfo.citaId}`,
      headers: { Authorization: token },
      params: {
        accountEmail: citasActiveInfo.accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    console.log(result);
    if (result?.Items) {
      const c = result?.Items[0];
      dispatch({
        type: 'set-editCitaInfo',
        value: c,
      });
      setService(c?.servicio);
      setCita(c?.cita);
      setDosis(c?.dosis);
      setDOB(c?.cita);
      setPlace(c?.place);
      setHomeAddress(c?.address);
      setPacientes(c.pacientes);
      setCita(c.cita);
      setProducts(c?.products);
      setPago(c.pago);
      setPhone(c.phone);
      setEmail(c.email);
      setCreated(c.created);
      setPlace(c.place);
      setHomeAddress(c.address);
      setStatus(c.status);

      // dispatch({
      //   type: 'set-pacientes',
      //   value: result.Items,
      // });
    }
  };
  const uploadData = async (data) => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'put',
      url: `https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/citas/${data.citaId}`,
      headers: { Authorization: token },
      data,
    }).then((res) => res.data).catch((err) => err);
    console.log(result);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Base De Datos Actualizada',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    setLoading(false);
    setSuccess(true);
    dispatch({
      type: 'set-dialogCitasActive',
      value: false,
    });
  };
  const updatePacient = (e) => {
    console.log(e);
    const p = [...pacientes];
    const t = p.map((f) => {
      if (f.pacienteId === e.pacienteId) {
        // eslint-disable-next-line no-param-reassign
        f.status = f.status ? 0 : 1;
        return f;
      }
      return f;
    });
    setPacientes(t);
  };
  const clearForm = () => {
    setDOB(dayjs());
    setLoading(false);
    setSuccess(false);
    setDobError(false);
    setHomeAddress('');
    setHomeAddressError(false);
    setPlaceError(false);
    setCita(false);
    setOpenDate(false);
    setService('');
    setPlaceChoices([]);
    setPacientes([]);
    setActiveStep(0);
  };

  const validCita = () => {
    if (cita) {
      if (!status && pago) {
        const c = dayjs(cita).valueOf();
        if (c > dayjs().subtract(40, 'minute').valueOf() && c < dayjs().add(40, 'minute').valueOf()) {
          return 'CitaValida';
        }
        return 'Fecha No Valida';
      }
      return 'Status/Pago No Valido';
    }
    if (!status && pago) {
      return 'Cita Sin Asignar';
    }
    return 'Status/Pago No Valido';
  };
  const getProps = () => {
    const r = {
      cita: cita ? dob : false,
      status: !pacientes.filter((e) => e.status === 0).length,
      pago: state.editCitaInfo?.pago,
      pacientes,
    };
    return r;
  };
  useEffect(() => {
    clearForm();
    getData();
    return () => {
      window.history.pushState('main', 'privatehc', '/');
    };
  }, []);

  return (
    <>
      {
          state.dialogPowerUserPatientInfo ? <DialogPowerUserPatient /> : null
        }
      <Dialog
        fullScreen={isMobile}
        open={state.dialogCitasActive}
        // onClose={() => {
        //   dispatch({
        //     type: 'set-dialogCitasActive',
        //     value: false,
        //   });
        // }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              {validCita()}
              {' '}
              {validCita() === 'Cita Valida' ? <Check style={{ color: green[500] }} /> : <Block color="secondary" />}
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogCitasActive',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Información  de la cita, de los pacientes vacunados y
            su información personal
          </DialogContentText>
          <List
            component="nav"
            aria-labelledby="list-products"
            subheader={(
              <ListSubheader component="div" id="nested-list-products">
                Productos
              </ListSubheader>
      )}
            className={classes.root}
          >
            {products.map((e) => (
              <ListItem key={e.id}>
                <ListItemText
                  primary={e.title}
                  secondary={e.productType}
                />
              </ListItem>
            ))}
          </List>
          <List
            component="nav"
            aria-labelledby="list-info"
            subheader={(
              <ListSubheader component="div" id="nested-list-info">
                Informacion
              </ListSubheader>
      )}
            className={classes.root}
          >
            <ListItem>
              <ListItemText
                primary="Nombre De Cuenta"
                secondary={state.citasActiveInfo.accountEmail}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Fecha Cita"
                secondary={cita ? dayjs(cita).format('DD/MM/YYYY HH:mm') : '-'}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Dosis"
                secondary={dosis || 0}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Lugar Cita"
                secondary={place || '-'}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Pago" />
              <ListItemSecondaryAction><Chip size="small" label={pago ? 'Completado' : 'Pendiente'} color={pago ? 'secondary' : 'default'} /></ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Status" />
              <ListItemSecondaryAction><Chip size="small" label={pacientes.filter((e) => e.status === 0).length ? 'Pendiente' : 'Completado'} color={pacientes.filter((e) => e.status === 0).length ? 'status' : 'secondary'} /></ListItemSecondaryAction>
            </ListItem>
          </List>
          <List
            className={classes.root}
            subheader={(
              <ListSubheader component="div" id="nested-list-subheader">
                Pacientes
              </ListSubheader>
      )}
          >
            {
                      pacientes.map((e, i) => (
                        <ListItem
                          key={`${e.pacientId} ${i}`}
                          role={undefined}
                          dense
                          button
                          onClick={() => updatePacient(e)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={e.status}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': e.pacientId }}
                            />

                          </ListItemIcon>
                          <ListItemText
                            id={e.pacientId}
                            primary={(
                              <>
                                {e?.name}
                                {' '}
                                {e?.lastName}
                              </>
                            )}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="comments"
                              onClick={() => {
                                dispatch({
                                  type: 'set-editPowerUserPatientInfo',
                                  value: e,
                                });
                                dispatch({
                                  type: 'set-dialogPowerUserPatientInfo',
                                  value: true,
                                });
                              }}
                            >
                              <NavigateNext />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))
                      }
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              clearForm();
              dispatch({
                type: 'set-dialogCitasActive',
                value: false,
              });
            }}
          >
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                uploadData({
                  ...editCitaInfo,
                  ...{
                    updated: dayjs().valueOf(),
                    pacientes,
                    status: pacientes.filter((e) => e.status === 0).length ? 0 : 1,
                  },
                });
              }}
            >
              Guardar
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>

        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogCitasActive;

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  LinearProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Chip,
  Divider,
} from '@material-ui/core';
import { QRCode } from 'react-qr-svg';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import {
  Close,
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function DialogNewPaciente(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Citas
  const [valid, setValid] = useState([]);
  const [all, setAll] = useState([]);
  const [showSelect, setShowSelect] = useState(false);

  const getData = async () => {
    setLoading(true);
    const token = await Auth.currentSession()
      .then((data) => data.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/citas',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      const citasValidas = result.Items.filter((e) => e.pago === 1 && e.status === 0)
        .sort((a, b) => b.citaId - a.citaId);
      const todasLasCitas = result.Items.sort((a, b) => b.citaId - a.citaId)
        .sort((a, b) => b.citaId - a.citaId);
      const multi = citasValidas.length > 1 ? true : todasLasCitas.length > 1;

      console.log(citasValidas.length > 1);

      console.log(todasLasCitas.length > 1);
      console.log(citasValidas);
      console.log(todasLasCitas);
      console.log(multi);
      setValid(citasValidas);
      setAll(todasLasCitas);
      setShowSelect(multi);
    }
    setLoading(false);
  };

  const clearForm = () => {
    setLoading(false);
    setSuccess(false);
    setShowSelect(false);
    setValid([]);
    setAll([]);
  };
  const setSelected = (e) => {
    setValid([e]);
    setShowSelect(false);
  };
  useEffect(() => {
    clearForm();
    getData();
  }, []);

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        open={state.dialogQR}
        onClose={() => {
          dispatch({
            type: 'set-dialogQR',
            value: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              Codigo QR
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogQR',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {
            loading ? <LinearProgress /> : all.length === 0 ? (
              <DialogContentText>
                El código QR se  generará al momento de asignar la cita para la aplicación.
              </DialogContentText>
            ) : null
          }

          {
            showSelect && valid.length > 1 ? (
              <>
                <DialogContentText>
                  Selecione una de las citas dispobiles en su perfil
                </DialogContentText>
                <List className={classes.root}>
                  {
                valid.map((e) => (
                  <>
                    <ListItem
                      button
                      onClick={() => setSelected(e)}
                    >
                      <ListItemText
                        primary={(
                          <Typography variant="h6" gutterBottom>
                            {e.servicio}
                          </Typography>
                      )}
                        secondary={(
                          <>
                            <Typography
                              variant="body2"
                              gutterBottom
                              color="textPrimary"
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                gutterBottom
                                color="textPrimary"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Creada
                              </Typography>
                              {' '}
                              {dayjs(e.created).format('DD/MM/YYYY hh:mm A')}
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              color="textPrimary"
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                gutterBottom
                                color="textPrimary"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Cita
                              </Typography>
                              {' '}
                              {dayjs(e.cita).format('DD/MM/YYYY hh:mm A')}
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              color="textPrimary"
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                gutterBottom
                                color="textPrimary"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Pago
                              </Typography>
                              {' '}
                              <Chip
                                label={e.pago === 1 ? 'Completado' : 'Pendiente'}
                                clickable
                                color={e.pago === 1 ? 'secondary' : 'default'}
                                size="small"
                                variant="outlined"
                              />
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              color="textPrimary"
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                gutterBottom
                                color="textPrimary"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Status
                              </Typography>
                              {' '}
                              <Chip
                                label={e.status === 1 ? 'Completado' : 'Pendiente'}
                                clickable
                                color={e.status === 1 ? 'secondary' : 'default'}
                                size="small"
                                variant="outlined"
                              />
                            </Typography>
                          </>
                      )}
                      />
                    </ListItem>
                    <Divider />
                  </>
                ))
              }
                </List>
              </>
            ) : null
          }
          {
            showSelect && valid.length === 0 && all.length > 0 ? (
              <>
                <DialogContentText>
                  Selecione una de las citas dispobiles en su perfil
                </DialogContentText>
                <List className={classes.root}>
                  {
                all.map((e) => (
                  <>
                    <ListItem
                      button
                      onClick={() => setSelected(e)}
                    >
                      <ListItemText
                        primary={(
                          <Typography variant="h6" gutterBottom>
                            {e.servicio}
                          </Typography>
                        )}
                        secondary={(
                          <>
                            <Typography
                              variant="body2"
                              gutterBottom
                              color="textPrimary"
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                gutterBottom
                                color="textPrimary"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Creada
                              </Typography>
                              {' '}
                              {dayjs(e.created).format('DD/MM/YYYY hh:mm A')}
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              color="textPrimary"
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                gutterBottom
                                color="textPrimary"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Cita
                              </Typography>
                              {' '}
                              {dayjs(e.cita).format('DD/MM/YYYY hh:mm A')}
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              color="textPrimary"
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                gutterBottom
                                color="textPrimary"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Pago
                              </Typography>
                              {' '}
                              <Chip
                                label={e.pago === 1 ? 'Completado' : 'Pendiente'}
                                clickable
                                color={e.pago === 1 ? 'secondary' : 'default'}
                                size="small"
                                variant="outlined"
                              />
                            </Typography>
                            <Typography
                              variant="body2"
                              gutterBottom
                              color="textPrimary"
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                gutterBottom
                                color="textPrimary"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Status
                              </Typography>
                              {' '}
                              <Chip
                                label={e.status === 1 ? 'Completado' : 'Pendiente'}
                                clickable
                                color={e.status === 1 ? 'secondary' : 'default'}
                                size="small"
                                variant="outlined"
                              />
                            </Typography>
                          </>
                      )}
                      />
                    </ListItem>
                    <Divider />
                  </>
                ))
              }
                </List>
              </>
            ) : null
          }

          {
            valid.length === 1 ? (
              <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                style={{ width: '100%' }}
                value={`https://www.privatehc.com?accountEmail=${state.accountEmail}&citaId=${valid[0].citaId}`}
              />
            ) : null
          }
          {
            valid.length === 0 && all.length === 1 ? (
              <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                style={{ width: '100%' }}
                value={`https://www.privatehc.com?accountEmail=${state.accountEmail}&citaId=${all[0].citaId}`}
              />
            ) : null
          }
        </DialogContent>
        <DialogActions>

          <Button
            onClick={() => {
              dispatch({
                type: 'set-dialogQR',
                value: false,
              });
            }}
            color="primary"
          >
            Cerrar
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogNewPaciente;

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  Grow,
  Fade,
  Paper,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import clsx from 'clsx';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import esLocale from 'dayjs/locale/es';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PhotoCamera, Close } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components
import CardPaciente from './CardPaciente';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const sexChoices = [{
  value: 'masculino',
  label: 'Masculino',
},
{
  value: 'femenino',
  label: 'Femenino',
}];

function getSteps() {
  return ['Información Personal', 'Vacunas', 'Foto'];
}

function DialogNewPaciente() {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Form Vars
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [sex, setSex] = useState('');
  const [dob, setDOB] = useState();
  const [image, setImage] = useState('');
  const [pacienteId, setPacienteId] = useState(0);
  const [birdPlace, setBirdPlace] = useState('');
  const [phone, setPhone] = useState('');
  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // Alert Dialog
  const [alertDialog, setAlertDialog] = useState(false);
  // Error Form
  const [nameError, setNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [sexError, setSexError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [birdPlaceError, setBirdPlaceError] = useState(false);

  const [anafilactico, setAnafilactico] = useState(false);
  const [confirmDate, setConfirmDate] = useState(false);
  const [selectedVacuna, setSelectedVacuna] = useState('');

  // Check Box
  const [vacunas, setVacunas] = useState([]);
  const [alergias, setAlergias] = useState('');

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const verifyAgainst = (e, value, setError) => {
    setError(e === value);
    return e === value;
  };
  const verifyValues = () => {
    switch (activeStep) {
      case 0:
        // eslint-disable-next-line no-unused-expressions
        [
          verifyAgainst(name, '', setNameError),
          verifyAgainst(lastName, '', setLastNameError),
          verifyAgainst(sex, '', setSexError),
          verifyAgainst(dob.format('DD/MM/YYYY'), dayjs().format('DD/MM/YYYY'), setDobError),
        ].filter((e) => e === true).length ? '' : handleNext();
        break;
      default:
        handleNext();
        console.log('INVALID');
    }
  };

  const resizeImg = (e) => {
    let fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      Resizer.imageFileResizer(
        e.target.files[0],
        500,
        500,
        'JPEG',
        80,
        0,
        (uri) => {
          console.log(uri.length);
          setImage(uri);
        },
        'base64',
        200,
        200,
      );
    }
  };
  const getData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      dispatch({
        type: 'set-pacientes',
        value: result.Items,
      });
    }
  };
  const uploadData = async (data) => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'put',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes',
      headers: { Authorization: token },
      data,
    }).then((res) => res.data).catch((err) => err);
    console.log(result);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Base De Datos Actualizada',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    setLoading(false);
    setSuccess(true);
    dispatch({
      type: 'set-editPatientInfo',
      value: {},
    });
    dispatch({
      type: 'set-dialogEditPatient',
      value: false,
    });
    await getData();
  };
  const deleteData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'delete',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
        pacienteId,
      },
    }).then((res) => res.data).catch((err) => err);
    console.log(result);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Base De Datos Actualizada',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    setLoading(false);
    setSuccess(true);
    dispatch({
      type: 'set-editPatientInfo',
      value: {},
    });
    dispatch({
      type: 'set-dialogEditPatient',
      value: false,
    });
    await getData();
  };
  const getVacunasData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/vacunas',
      headers: { Authorization: token },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      dispatch({
        type: 'set-vacunas',
        value: result.Items,
      });
    }
  };

  const clearForm = () => {
    setName('');
    setLastName('');
    setSex('');
    setDOB(dayjs());
    setImage('');
    setPhone('');
    setBirdPlace('');
    setVacunas([]);
    setAlergias('');
    setAnafilactico(false);
    setLoading(false);
    setSuccess(false);
    setNameError(false);
    setLastNameError(false);
    setSexError(false);
    setDobError(false);
    setPhoneError(false);
    setBirdPlaceError(false);
    setActiveStep(0);
    setPacienteId(0);
  };

  const getProps = () => {
    const r = {
      name,
      lastName,
      sex,
      dob: dob.format('DD/MM/YYYY'),
      image,
      edit: false,
      primary: false,
    };
    return r;
  };
  useEffect(() => {
    clearForm();
    getVacunasData();
    console.log('####DIALOG_UPDATE####');
    setAnafilactico(state.editPatientInfo?.anafilactico === 1);
    setName(state.editPatientInfo?.name);
    setLastName(state.editPatientInfo?.lastName);
    setSex(state.editPatientInfo?.sex);
    setDOB(dayjs(state.editPatientInfo?.dob));
    setImage(state.editPatientInfo?.image);
    setPacienteId(state.editPatientInfo?.pacienteId);
    setPhone(state.editPatientInfo?.phone);
    setBirdPlace(state.editPatientInfo?.birdPlace);
    setAlergias(state.editPatientInfo?.alergias);
    setVacunas(state.editPatientInfo?.vacunas);
  }, []);

  return (
    <>
      <Dialog onClose={() => setConfirmDate(false)} aria-labelledby="customized-dialog-title" open={confirmDate}>
        <DialogTitle>Fecha De Vacunación </DialogTitle>
        <DialogContent>
          <DialogContentText>
            En que fecha recibió la vacuna?
          </DialogContentText>
          <MuiPickersUtilsProvider utils={DayjsUtils} locale={esLocale}>
            <DatePicker
              required
              disableFuture
              fullWidth
              disableToolbar
              cancelLabel="Cancelar"
              okLabel="Aceptar"
              openTo="year"
              format="MMMM YYYY"
              views={['year', 'month']}
              value={dayjs(vacunas.find(
                (e) => e.vacunaCode === selectedVacuna,
              )?.date || dayjs().valueOf())}
              onChange={(e) => {
                let vTemp = [...vacunas];
                vTemp = vacunas.filter((f) => f.vacunaCode !== selectedVacuna);
                vTemp.push({
                  vacunaCode: selectedVacuna,
                  date: e.valueOf(),
                });
                setVacunas(vTemp);
                setConfirmDate(false);
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDate(false)} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">Borrar Usuario</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ⚠️ Atención, estos cambios no son reversibles
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertDialog(false)} color="primary">
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              disabled={loading}
              onClick={() => {
                setLoading(true);
                deleteData();
              }}
              color="secondary"
              autoFocus
            >
              Borrar
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={isMobile}
        open={state.dialogEditPatient}
        onClose={() => {
          dispatch({
            type: 'set-dialogEditPatient',
            value: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              Actualizar Usuario
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogEditPatient',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>

        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {
          activeStep === 0 ? (
            <>
              <Fade in={activeStep === 0}>
                <Paper elevation={0}>
                  <DialogContentText>
                    Información personal del usuario.
                  </DialogContentText>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        autoFocus
                        required
                        error={nameError}
                        margin="dense"
                        id="name"
                        label="Nombres"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setNameError(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        required
                        error={lastNameError}
                        margin="dense"
                        id="lastName"
                        label="Apellidos"
                        type="text"
                        fullWidth
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                          setLastNameError(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        required
                        error={sexError}
                        select
                        margin="dense"
                        id="sex"
                        label="Sexo"
                        type="text"
                        fullWidth
                        value={sex}
                        onChange={(e) => {
                          setSex(e.target.value);
                          setSexError(false);
                        }}
                      >
                        {sexChoices.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MuiPickersUtilsProvider utils={DayjsUtils} locale={esLocale}>
                        <DatePicker
                          required
                          error={dobError}
                          disableFuture
                          fullWidth
                          disableToolbar
                          cancelLabel="Cancelar"
                          okLabel="Aceptar"
                          openTo="year"
                          format="DD/MM/YYYY"
                          label="Fecha De Nacimiento"
                          views={['year', 'month', 'date']}
                          value={dob}
                          onChange={(e) => {
                            setDobError(false);
                            setDOB(e);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        required
                        margin="dense"
                        error={birdPlaceError}
                        id="birdPlace"
                        label="Lugar De Nacimiento"
                        type="text"
                        fullWidth
                        value={birdPlace}
                        onChange={(e) => {
                          setBirdPlace(e.target.value);
                          setBirdPlaceError(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MaterialUiPhoneNumber
                        margin="dense"
                        label="Teléfono"
                        error={phoneError}
                        required
                        regions="america"
                        defaultCountry="mx"
                        disableDropdown
                        fullWidth
                        autoFormat
                        countryCodeEditable={false}
                        value={phone}
                        onChange={(e) => {
                          setPhone(e);
                          setPhoneError(false);
                          console.log(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}
          {
          activeStep === 1 ? (
            <>
              <Fade in={activeStep === 1}>
                <Paper elevation={0}>
                  <DialogContentText>
                    ⚠️Por su salud, lea y complete el siguiente formulario y marque todas las casillas que apliquen para el paciente.
                  </DialogContentText>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={anafilactico}
                            onChange={(e) => {
                              setAnafilactico(e.target.checked);
                            }}
                            name="Choque"
                            color="secondary"
                          />
                          )}
                        label="Antecedente de reacción anafiláctica"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        margin="dense"
                        error={nameError}
                        variant="filled"
                        id="name"
                        label="Alergias"
                        type="text"
                        fullWidth
                        value={alergias}
                        onChange={(e) => {
                          setAlergias(e.target.value);
                        }}
                      />
                    </Grid>
                    {
                      state.vacunas.sort().map((v) => (
                        <Grid item xs={12} sm={6} md={6} key={`vacuna-${v}`}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={vacunas.filter(
                                  (f) => f.vacunaCode === v.vacunaCode,
                                ).length}
                                onChange={() => {
                                  if (vacunas.filter((f) => f.vacunaCode === v.vacunaCode).length) {
                                    setVacunas(vacunas.filter(
                                      (e) => e.vacunaCode !== v.vacunaCode,
                                    ));
                                  } else {
                                    const vTemp = [...vacunas];
                                    vTemp.push({
                                      vacunaCode: v.vacunaCode,
                                      date: dayjs().valueOf(),
                                    });
                                    setVacunas(vTemp);
                                    setSelectedVacuna(v.vacunaCode);
                                    setConfirmDate(true);
                                  }
                                }}
                                name={`check-${v.vacunaCode}`}
                                color="secondary"
                              />
                          )}
                            label={v.name}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}
          {
          activeStep === 2 ? (
            <>
              <Fade in={activeStep === 2}>
                <Paper elevation={0}>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography variant="body1" gutterBottom style={{ marginTop: 5 }}>
                        ⚠️Importante. Una fotografía del rostro es requerida para poder identificar al paciente durante la aplicación de vacunas.
                      </Typography>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        startIcon={<PhotoCamera />}
                        fullWidth
                      >
                        Cambiar Imagen
                        <input
                          type="file"
                          id="file"
                          accept="image/png, image/jpeg"
                          hidden
                          onChange={(e) => {
                            resizeImg(e);
                          }}
                        />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <img src={image || 'https://i1.wp.com/alkiansgroup.com/wp-content/uploads/2018/11/male-placeholder-image.jpg'} alt="Foto Perfil" className={classes.image} />
                    </Grid>
                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}
          {
activeStep === steps.length ? (
  <>
    <Fade in={activeStep === steps.length}>
      <Paper elevation={0}>
        <CardPaciente {...getProps()} />
      </Paper>
    </Fade>
  </>
) : null
}

        </DialogContent>
        <DialogActions>
          {
            activeStep === 0 ? (
              <Button
                color="secondary"
                onClick={() => setAlertDialog(true)}
              >
                Borrar Usuario
              </Button>
            ) : null
          }

          {activeStep === steps.length ? (
            <>
              <Button
                onClick={() => {
                  clearForm();
                  dispatch({
                    type: 'set-dialogEditPatient',
                    value: false,
                  });
                }}
              >
                Cancelar
              </Button>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    uploadData({
                      pacienteId,
                      accountEmail: state.accountEmail,
                      name,
                      lastName,
                      sex,
                      dob,
                      image,
                      vacunas,
                      alergias,
                      anafilactico: anafilactico ? 1 : 0,
                      birdPlace,
                      phone,
                    });
                  }}
                >
                  Actualizar
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </>
          ) : (
            <>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                // color="primary"
              >
                Regresar
              </Button>
              <Button
                onClick={verifyValues}
                color="primary"
              >
                Siguiente
              </Button>
            </>
          )}

        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogNewPaciente;

/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  Fab,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardActionArea,
  CardMedia,
} from '@material-ui/core';
import dayjs from 'dayjs';
// Store
import { store } from '../state/store';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 200,
  },
}));

function CardPaciente(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  return (
    <>
      <Card className={classes.root}>
        <CardActionArea onClick={() => {
          dispatch({
            type: 'set-editPatientInfo',
            value: props,
          });
          dispatch({
            type: 'set-dialogEditPatient',
            value: true,
          });
        }}
        >
          <CardMedia
            className={classes.media}
            component="img"
            src={props.image}
            title={`${props.name} ${props.lastName}`}
          />
          <CardContent>
            <Typography gutterBottom variant="h5">
              {`${props.name} ${props.lastName}`}
            </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Edad:
              {' '}
              {dayjs(props.dob).format('DD/MM/YYYY')}
            </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Sexo:
              {' '}
              {props.sex}
            </Typography>
          </CardContent>
        </CardActionArea>
        {
          props.edit ? (
            <CardActions>
              <Button
                size="small"
                onClick={() => {
                  dispatch({
                    type: 'set-editPatientInfo',
                    value: props,
                  });
                  dispatch({
                    type: 'set-dialogEditPatient',
                    value: true,
                  });
                }}
              >
                Editar

              </Button>
            </CardActions>
          ) : null
        }

      </Card>
    </>
  );
}

export default CardPaciente;

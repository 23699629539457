/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  LinearProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Chip,
  Divider,
  ListItemIcon,
} from '@material-ui/core';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import {
  Close,
  Person,
  Group,
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function DialogNewUserGroup(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Citas
  const [valid, setValid] = useState([]);
  const [all, setAll] = useState([]);
  const [showSelect, setShowSelect] = useState(false);

  const setSelected = (e) => {
    setValid([e]);
    setShowSelect(false);
  };
  useEffect(() => {
  }, []);

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        open={state.dialogNewUserGroup}
        onClose={() => {
          dispatch({
            type: 'set-dialogNewUserGroup',
            value: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              Crear Usuario o Grupo
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogNewUserGroup',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>

          <List component="nav" aria-label="main mailbox folders">
            <ListItem
              button
              onClick={() => {
                dispatch({
                  type: 'set-dialogNewUserGroup',
                  value: false,
                });
                dispatch({
                  type: 'set-dialogNewPatient',
                  value: true,
                });
              }}
            >
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Nuevo Usuario" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                dispatch({
                  type: 'set-dialogNewUserGroup',
                  value: false,
                });
                dispatch({
                  type: 'set-dialogNewGroup',
                  value: true,
                });
              }}
            >
              <ListItemIcon>
                <Group />
              </ListItemIcon>
              <ListItemText primary="Nuevo Grupo" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>

          <Button
            onClick={() => {
              dispatch({
                type: 'set-dialogNewUserGroup',
                value: false,
              });
            }}
            color="primary"
          >
            Cerrar
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogNewUserGroup;

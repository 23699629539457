/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  Fab,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardActionArea,
  CardMedia,
  Paper,
} from '@material-ui/core';
import {
  Apartment, Event, Phone, BusinessCenter, People, Assessment,
} from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
// Components
import AppBar from '../components/AppBar';
import CardPaciente from '../components/CardPaciente';
import DialogNewPaciente from '../components/DialogNewPaciente';
import DialogUpdatePaciente from '../components/DialogUpdatePaciente';
import Markdown from '../components/Markdown';
// Markdown
import terminosDeUso from '../markdown/TerminosDeUso';
// Store
import { store } from '../state/store';
// Logo
import logo from '../svg/qr-code_2.svg';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    //  flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  container: {
    display: 'flex !important',
    height: '100%',
  },
  mediaLight: {
    backgroundColor: theme.palette.primary.light,

  },
  mediaMain: {
    backgroundColor: theme.palette.primary.main,

  },
  mediaDark: {
    backgroundColor: theme.palette.primary.dark,

  },
  image: {
    float: 'none',
    width: '100%',
    height: '100%',
  },
  mediaContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: '#fff',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: ' 0 20%',
    textAlign: 'center',
  },
  mediaIcon: {
    fontSize: 'xxx-large',
  },
  logo: {
    height: 40,
  },
}));

function PowerUserHome() {
  const classes = useStyles();

  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  useEffect(() => {
    dispatch({
      type: 'set-title',
      value: isMobile ? 'PRIVATEHC' : '',
    });
  }, []);

  return (
    <>
      <Container className={classes.cardGrid} maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <img src="https://privatehcpublic.s3.amazonaws.com/iamge_banderas_3.jpg" alt="Girl in a jacket" className={classes.image} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="stretch"
              className={classes.container}
            >

              <Grid item xs={6} className={classes.mediaDark}>
                <Button
                  style={{ height: '100%', width: '100%' }}
                  onClick={() => {
                    dispatch({
                      type: 'set-page',
                      value: 'Citas',
                    });
                  }}
                >
                  <div className={classes.mediaContainer}>
                    <div>
                      <Event className={classes.mediaIcon} />
                    </div>
                    <div>
                      <Typography variant={isMobile ? 'subtitle2' : 'h6'} gutterBottom>
                        Citas
                      </Typography>
                    </div>
                  </div>
                </Button>

              </Grid>
              <Grid item xs={6} className={classes.mediaMain}>
                <Button
                  style={{ height: '100%', width: '100%' }}
                  onClick={() => {
                    dispatch({
                      type: 'set-page',
                      value: 'Reporting',
                    });
                  }}
                >
                  <div className={classes.mediaContainer}>
                    <div>
                      <Assessment className={classes.mediaIcon} />
                    </div>
                    <div>
                      <Typography variant={isMobile ? 'subtitle2' : 'h6'} gutterBottom>
                        Reportes
                      </Typography>
                    </div>
                  </div>
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.mediaMain}>
                <Button
                  style={{ height: '100%', width: '100%' }}
                  onClick={() => {
                    dispatch({
                      type: 'set-dialogQRScanner',
                      value: true,
                    });
                  }}
                >
                  <div className={classes.mediaContainer}>
                    <div>
                      <img src={logo} className={classes.logo} alt="logo" />
                    </div>
                    <div>
                      <Typography variant={isMobile ? 'subtitle2' : 'h6'} gutterBottom>
                        Escanear QR
                      </Typography>
                    </div>
                  </div>
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.mediaLight}>
                <Button
                  style={{ height: '100%', width: '100%' }}
                  onClick={() => {
                    dispatch({
                      type: 'set-page',
                      value: 'PowerUsers',
                    });
                  }}
                >
                  <div className={classes.mediaContainer}>
                    <div>
                      <People className={classes.mediaIcon} />
                    </div>
                    <div>
                      <Typography variant={isMobile ? 'subtitle2' : 'h6'} gutterBottom>
                        Power Users
                      </Typography>
                    </div>
                  </div>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default PowerUserHome;

/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  Grow,
  Fade,
  Paper,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import Client from 'shopify-buy';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import clsx from 'clsx';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import esLocale from 'dayjs/locale/es';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PhotoCamera, Close, Satellite } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components
import CardCita from './CardCita';
import CardTotal from './CardTotal';

const client = Client.buildClient({
  storefrontAccessToken: 'db49f66623fa938339b44ae88554da62',
  domain: 'private-health-club.myshopify.com',
  language: 'es-MX',
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function getSteps() {
  return ['Servicio', 'Contacto', 'Usuario'];
}

function DialogApointment(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Form Vars
  const [image, setImage] = useState('');
  const [phone, setPhone] = useState('');
  const [service, setService] = useState('');
  const [email, setEmail] = useState('');
  const [selected, setSelected] = useState([]);
  // Shopify
  const [products, setProducts] = useState([]);
  const [checkout, setCheckout] = useState({ lineItems: [] });
  const [citaId, setCitaId] = useState(0);

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // Error Form
  const [phoneError, setPhoneError] = useState(false);
  const [serviceError, setServiceError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [selectedError, setSelectedError] = useState(false);

  // Check Box
  const [vacunas, setVacunas] = useState([]);
  const [alergias, setAlergias] = useState('');

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const verifyAgainst = (e, value, setError) => {
    setError(e === value);
    return e === value;
  };
  const verifyValues = () => {
    switch (activeStep) {
      case 0:
        // eslint-disable-next-line no-unused-expressions
        [
          verifyAgainst(service, '', setServiceError),
        ].filter((e) => e === true).length ? '' : handleNext();
        break;
      case 1:
        // eslint-disable-next-line no-unused-expressions
        [
          verifyAgainst(email, '', setEmailError),
          verifyAgainst(phone, '', setPhoneError),
        ].filter((e) => e === true).length ? '' : handleNext();
        break;
      case 2:
        // eslint-disable-next-line no-unused-expressions
        [
          verifyAgainst(selected.length.toString(), '0', setSelectedError),
        ].filter((e) => e === true).length ? '' : createChekout();
        break;
      default:
        handleNext();
        console.log('INVALID');
    }
  };

  const getCitas = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/citas',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      dispatch({
        type: 'set-citas',
        value: result.Items,
      });
    }
  };
  const getData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      dispatch({
        type: 'set-notificationMessage',
        value: 'Datos Actualizados',
      });
      dispatch({
        type: 'set-notification',
        value: true,
      });
      dispatch({
        type: 'set-pacientes',
        value: result.Items,
      });
    }
  };
  const uploadData = async (data) => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'post',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/citas',
      headers: { Authorization: token },
      data,
    }).then((res) => res.data).catch((err) => err);
    console.log(result);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Base De Datos Actualizada',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    setLoading(false);
    setSuccess(true);
    getCitas();
    dispatch({
      type: 'set-dialogNewApointment',
      value: false,
    });
  };

  const clearForm = () => {
    setService('');
    setSelected([]);
    setPhone('');
    setEmail('');
    setEmailError(setSelectedError);
    setEmailError(false);
    setServiceError(false);
    setLoading(false);
    setSuccess(false);

    setActiveStep(0);
  };

  const getProps = () => {
    const r = {
      selected,
      servicio: service,
      total: checkout.totalPrice,
      unit: 121,
    };
    return r;
  };
  const getProducts = () => {
    client.product.fetchAll().then((res) => {
      setProducts(res);
    });
  };
  const createChekout = () => {
    const variantId = products.find((e) => e.handle === service).variants[0].id;
    const lineItems = [{ variantId, quantity: selected.length }];

    const c = new Date().valueOf();
    setCitaId(c);

    const note = JSON.stringify({
      pacientes: state.pacientes.filter((e) => selected.includes(e.pacienteId)).map((m) => `${m.name} ${m.lastName}`), email: state.accountEmail, phone, citaId: c,
    });
    client.checkout.create({ email: state.accountEmail, lineItems, note }).then((res) => {
      setCheckout(res);
      handleNext();
    });
  };
  const openCheckout = () => {
    window.open(checkout.webUrl);
  };
  const updateCheckOut = () => {
    const variantId = products.find((e) => e.handle === service).variants[0].id;
    const lineItemsToAdd = [{ variantId, quantity: selected.length }];
    const checkoutId = checkout.id;
    client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((res) => {
      console.log(res);
      setCheckout(res);
    });
  };
  useEffect(() => {
    clearForm();
    getData();
    getProducts();
    setEmail(state.accountEmail);
    console.log('$$$$$$$$$$$$$$$$$$');
    console.log(props);
  }, []);
  useEffect(() => {
    console.log('_-----------------------');
    console.log(checkout);
  }, [checkout]);

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        open={state.dialogNewApointment}
        onClose={() => {
          dispatch({
            type: 'set-dialogNewApointment',
            value: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              Crear Nueva Cita
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogNewApointment',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {
          activeStep === 0 ? (
            <>
              <Fade in={activeStep === 0}>
                <Paper elevation={0}>
                  <DialogContentText>
                    Seleccion el servicio que desea programar una cita.
                  </DialogContentText>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        required
                        margin="dense"
                        error={serviceError}
                        select
                        id="service"
                        label="Servicio"
                        type="text"
                        fullWidth
                        value={service}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setService(e.target.value);
                          setServiceError(false);
                        }}
                      >
                        {products.map((product) => (
                          <MenuItem key={product.handle} value={product.handle}>
                            {product.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}
          {
          activeStep === 1 ? (
            <>
              <Fade in={activeStep === 1}>
                <Paper elevation={0}>
                  <DialogContentText>
                    Métodos de contacto para confirmar su cita por correo electronico y mesaje SMS de texto
                  </DialogContentText>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        required
                        error={emailError}
                        id="name"
                        label="Correo Electronico"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailError(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MaterialUiPhoneNumber
                        margin="dense"
                        label="Teléfono Celular"
                        error={phoneError}
                        required
                        regions="america"
                        defaultCountry="mx"
                        disableDropdown
                        fullWidth
                        autoFormat
                        countryCodeEditable={false}
                        value={phone}
                        onChange={(e) => {
                          setPhone(e);
                          setPhoneError(false);
                          console.log(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}
          {
          activeStep === 2 ? (
            <>
              <Fade in={activeStep === 2}>
                <Paper elevation={0}>
                  <DialogContentText>
                    Selecciona los pacientes que acudiran a la cita.
                  </DialogContentText>
                  {
                        selectedError ? (
                          <Typography variant="body1" gutterBottom color="secondary" style={{ marginTop: 5 }}>
                            Es necesario seleccionar al menos a un paciente.
                          </Typography>
                        ) : null
                      }
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <List className={classes.root}>
                        {
              state.pacientes.map((e, i) => (
                <ListItem key={e.pacienteId}>
                  <ListItemText primary={`${e.name} ${e.lastName}`} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      checked={selected.includes(e.pacienteId)}
                      name={e.pacienteId}
                      inputProps={{ 'aria-labelledby': e.pacienteId }}
                      onChange={(c) => {
                        const n = parseInt(c.target.name, 10);
                        if (selected.includes(n)) {
                          setSelected(selected.filter((f) => f !== n));
                        } else {
                          const temp = [...selected];
                          temp.push(n);
                          setSelected(temp);
                          setSelectedError(false);
                        }
                      }}
                    />

                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }

                      </List>
                    </Grid>
                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}
          {
activeStep === steps.length ? (
  <>
    <Fade in={activeStep === steps.length}>
      <Paper elevation={0}>
        <DialogContentText>
          Una vez que su pago sea confirmado, contactaremos para programar su cita
        </DialogContentText>
        <CardTotal {...getProps()} />
      </Paper>
    </Fade>
  </>
) : null
}

        </DialogContent>
        <DialogActions>

          {activeStep === steps.length ? (
            <>
              <Button
                onClick={() => {
                  clearForm();
                  dispatch({
                    type: 'set-dialogNewApointment',
                    value: false,
                  });
                }}
                // color="secondary"
              >
                Cancelar
              </Button>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  onClick={() => {
                    openCheckout();
                    setLoading(true);
                    uploadData({
                      accountEmail: state.accountEmail,
                      created: dayjs(),
                      servicio: service,
                      citaId,
                      doctor: '',
                      status: 0,
                      pago: 0,
                      pacientes: state.pacientes.filter((f) => selected.includes(f.pacienteId)).map((m) => ({
                        status: 0,
                        pacienteId: m.pacienteId,
                        name: m.name,
                        lastName: m.lastName,
                      })),
                      email,
                      phone,
                    });
                  }}
                >
                  Pagar
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </>
          ) : (
            <>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                // color="primary"
              >
                Regresar
              </Button>
              <Button
                onClick={verifyValues}
                color="primary"
              >
                Siguiente
              </Button>
            </>
          )}

        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogApointment;

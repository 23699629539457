const aviso = `Lea estos Términos de Uso (&quot;Términos&quot;, &quot;Términos de uso&quot;) detenidamente antes de utilizar el sitio web privatehc.com

Su acceso y uso del sitio web está condicionado a su aceptación y cumplimiento de estos Términos de Uso. Estos Términos se aplican a todos los visitantes, usuarios y otras personas que acceden o usen el sitio web.

Al acceder o utilizar el servicio, usted acepta estar sujeto a estos Términos. Si no está de acuerdo con alguna parte de los términos, no podrá acceder al servicio.

Terminación

Podemos rescindir o suspender el acceso a nuestro servicio de inmediato, sin previo aviso o responsabilidad, por cualquier motivo, incluso, sin limitación si usted incumple los Términos.

Todas las disposiciones de los Términos que, por su naturaleza, deberían sobrevivir a la terminación, seguirán vigentes, incluidas, entre otras, las disposiciones de propiedad, las renuncias de garantía, la indemnización y las limitaciones de responsabilidad.

Enlaces a otros sitios web

Nuestro sitio web puede contener enlaces a otros sitios web externos o servicios de terceros que no son propiedad, ni están controlados por privatehc.com.

Privatehc.com no tiene control, no asume ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios web o servicios de terceros. Además, usted reconoce y acepta que privatehc.com no será responsable, directa o indirectamente, de ningún daño o pérdida causada o supuestamente causada por o en relación con el uso o la dependencia de dicho contenido externo, bienes o servicios disponibles en o a través de dichos sitios web o servicios externos.

Contenido

Queda estrictamente prohibida la difusión en cualquier forma o medio sin la autorización escrita por parte de PrivateHC, así como la copia en partes o su totalidad del contenido, servicios y productos, términos, reglas de uso, toma de imágenes relacionadas con las actividades, beneficios y bienes ofertados por medio del sitio. Cualquier violación significará la cancelación de la membresía y cancelación del acceso sin derecho alguno a reembolso.

Cambios

Nos reservamos el derecho, a nuestro exclusivo criterio, de modificar o reemplazar estos Términos en cualquier momento. Si una revisión es material, intentaremos proporcionar un aviso de al menos 30 días antes de que entren en vigencia los nuevos términos. Lo que constituye un cambio material se determinará a nuestro exclusivo criterio.

Cualquier violación a las condiciones previamente descritas representa la terminación de su membresía y acceso al sitio y sus beneficios, sin derecho a reembolso.

Contáctenos

Si tiene alguna pregunta sobre estos Términos de Uso, contáctenos: [privatehc.com](http://www.privatehc.com/)`;
export default aviso;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Link,
} from '@material-ui/core';
import { Room } from '@material-ui/icons';
import GoogleMapReact from 'google-map-react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
// Components
import AppBar from '../components/AppBar';
import CardPaciente from '../components/CardPaciente';
import DialogNewPaciente from '../components/DialogNewPaciente';
import DialogUpdatePaciente from '../components/DialogUpdatePaciente';
import Markdown from '../components/Markdown';
// Markdown
import places from '../places/Places';
// Store
import { store } from '../state/store';

const key = 'AIzaSyDnsaH6YtS51AxYYGIb9Q6tnIiN0JrppYQ';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
}));

function PlacesSimple() {
  const classes = useStyles();
  const [placeChoices, setPlaceChoices] = useState([]);

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 12,
  };
  const getData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/places',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      setPlaceChoices(result.Items);
      dispatch({
        type: 'set-notificationMessage',
        value: 'Datos Actualizados',
      });
      dispatch({
        type: 'set-notification',
        value: true,
      });
    }
  };
  useEffect(() => {
    getData();
    dispatch({
      type: 'set-title',
      value: 'Ubicaciones',
    });
  }, []);

  return (
    <>
      <Container className={classes.cardGrid} maxWidth="lg">
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h6" gutterBottom>
            Las ubicaciones seran definidas al momento de asignar la cita.
          </Typography>
        </Grid>
      </Container>
    </>
  );
}

export default PlacesSimple;

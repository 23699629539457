const aviso = `Privatehc.com opera [privatehc.com](http://privatehc.com/), de ahora en adelante denominado como el &quot;Sitio&quot;. Esta página le informa sobre nuestras políticas con respecto a la recopilación, uso y divulgación de la información personal que recibimos de los usuarios del sitio.

Utilizamos su información personal solo para mejorar el sitio. Al usar el Sitio, usted acepta la recopilación y el uso de información de acuerdo con esta política.

Recolección de información y uso

Al usar nuestro sitio, podemos pedirle que nos proporcione cierta información de identificación personal que se puede utilizar para contactarlo o identificarlo. La información de identificación personal puede incluir, entre otros, su nombre, teléfono y correo electrónico (&quot;Información personal&quot;).

Dato de registro

Al igual que muchos operadores de sitios, recopilamos información que su navegador envía cada vez que visita nuestro Sitio (&quot;Datos de registro&quot;).

Estos datos de registro pueden incluir información como la dirección del Protocolo de Internet (&quot;IP&quot;) de su computadora, el tipo de navegador, la versión del navegador, las páginas de nuestro sitio que usted visita, la hora y la fecha de su visita, el tiempo dedicado a esas páginas, entre otras estadísticas.

Además, podemos usar servicios de terceros como Google Analytics u otros similares que recopilan, monitorean y analizan datos de nuestros visitantes.

Comunicaciones

Podemos utilizar su información personal para contactarlo con boletines informativos, materiales de marketing o promocionales y otra información que pueda responder a su interés.

Cookies

Las cookies son archivos con una pequeña cantidad de datos, que pueden incluir un identificador único anónimo. Las cookies se envían a su navegador desde un sitio web y se almacenan en el disco duro de su computadora.

Al igual que muchos sitios, utilizamos cookies para recopilar información. Puede indicar a su navegador que rechace todas las cookies o que indique cuándo se envía una cookie. Sin embargo, si no acepta las cookies, es posible que no pueda usar algunas partes de nuestro Sitio.

Seguridad

La seguridad de su información personal es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger su información personal, no podemos garantizar su seguridad absoluta.

Cambios a esta política de privacidad

Esta Política de privacidad entra en vigencia a partir Diciembre de 2020 y permanecerá vigente, excepto con respecto a cualquier cambio en sus disposiciones en el futuro, que entrará en vigencia inmediatamente después de su publicación en esta página.

Nos reservamos el derecho de actualizar o cambiar nuestra Política de privacidad en cualquier momento y usted debe verificar esta Política de privacidad periódicamente. Su uso continuado del Servicio después de que publiquemos cualquier modificación a la Política de privacidad en esta página, constituirá su reconocimiento de las modificaciones y su consentimiento para cumplir y estar sujeto a la Política de privacidad modificada.

Si realizamos algún cambio importante en esta Política de privacidad, se lo notificaremos a través de la dirección de correo electrónico que nos haya proporcionado o colocando un aviso destacado en nuestro sitio web.

Esta membresia solo tiene una duración de 12 meses.

Contáctenos

Si tiene alguna pregunta sobre esta Política de privacidad, contáctenos: [privatehc.com](http://privatehc.com/)`;
export default aviso;

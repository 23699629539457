/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  Grow,
  Fade,
  Paper,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  ListItemIcon,
  ListItem,
  List,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import esLocale from 'dayjs/locale/es';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  PhotoCamera, Close, Comment, NavigateNext,
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components
import CardPaciente from './CardPaciente';
import DialogPowerUserPatient from './DialogPowerUserPatient';
import CardCita from './CardCita';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const sexChoices = [{
  value: 'masculino',
  label: 'Masculino',
},
{
  value: 'femenino',
  label: 'Femenino',
}];

function getSteps() {
  return ['Información', 'Pacientes'];
}

function DialogPowerUser(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Form Vars
  const [dob, setDOB] = useState();
  const [place, setPlace] = useState('');
  const [homeAddress, setHomeAddress] = useState('');

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // Error Form

  const [dobError, setDobError] = useState(false);
  const [placeError, setPlaceError] = useState(false);
  const [homeAddressError, setHomeAddressError] = useState(false);

  const [cita, setCita] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [service, setService] = useState('');
  const [placeChoices, setPlaceChoices] = useState([]);
  const [pacientes, setPacientes] = useState([]);

  const [group, setGroup] = useState('');
  const [groups, setGroups] = useState([]);
  const [status, setStatus] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  // Alert Dialog
  const [alertDialog, setAlertDialog] = useState(false);

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const verifyAgainst = (e, value, setError) => {
    const validation = e === value || e === undefined || e === null;
    setError(validation);
    return validation;
  };
  const verifyValues = () => {
    const validations = [];
    switch (activeStep) {
      case 0:
        // eslint-disable-next-line no-unused-expressions
        if (cita) {
          validations.push(verifyAgainst(place, '', setPlaceError));
          if (place === 'home' || place === 'custom') {
            validations.push(verifyAgainst(homeAddress, '', setHomeAddressError));
          }
          validations.push(verifyAgainst(place, '', setPlaceError));
        }
        // eslint-disable-next-line no-unused-expressions
        validations.filter((e) => e).length ? '' : handleNext();
        // [
        //   verifyAgainst(name, '', setNameError),
        //   verifyAgainst(lastName, '', setLastNameError),
        //   verifyAgainst(sex, '', setSexError),
        //   verifyAgainst(dob.format('DD/MM/YYYY'), dayjs().format('DD/MM/YYYY'), setDobError),
        //   verifyAgainst(birdPlace, '', setBirdPlaceError),
        // ].filter((e) => e === true).length ? '' : handleNext();
        break;
      default:
        handleNext();
        console.log('INVALID');
    }
  };
  const getTableData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/powerusers',
      headers: { Authorization: token },
    }).then((res) => res.data).catch((err) => err);
    console.log(result.Items);
    setLoading(false);
    setSuccess(true);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Datos Actualizados',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    dispatch({
      type: 'set-citasTable',
      value: result.Items,
    });
    dispatch({
      type: 'set-loading',
      value: false,
    });
  };
  const getData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/groups',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Groups) {
      setGroups(result.Groups);
    }
  };

  const deleteData = async (data) => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'delete',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/powerusers',
      headers: { Authorization: token },
      data,
    }).then((res) => res.data).catch((err) => err);

    console.log(result);
    setLoading(false);
    setSuccess(true);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Usuario Borrado',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    dispatch({
      type: 'set-loading',
      value: false,
    });
    dispatch({
      type: 'set-dialogPowerUser',
      value: false,
    });

    getTableData();
  };

  const uploadData = async (data) => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'put',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/powerusers',
      headers: { Authorization: token },
      data,
    }).then((res) => res.data).catch((err) => err);

    console.log(result);
    setLoading(false);
    setSuccess(true);
    dispatch({
      type: 'set-loading',
      value: false,
    });
    dispatch({
      type: 'set-dialogPowerUser',
      value: false,
    });
    getTableData();
  };

  const clearForm = () => {
    setDOB(dayjs());
    setLoading(false);
    setSuccess(false);
    setDobError(false);
    setHomeAddress('');
    setHomeAddressError(false);
    setPlaceError(false);
    setCita(false);
    setOpenDate(false);
    setService('');
    setPlace('');
    setPlaceChoices([]);
    setPacientes([]);
    setActiveStep(0);
  };

  const getProps = () => {
    const r = {
      cita: cita ? dob : false,
      status: !pacientes.filter((e) => e.status === 0).length,
      pago: state.editCitaInfo?.pago,
      pacientes,
    };
    return r;
  };

  const updatePacient = (e) => {
    console.log(e);
    const p = [...pacientes];
    const t = p.map((f) => {
      if (f.pacienteId === e.pacienteId) {
        // eslint-disable-next-line no-param-reassign
        f.status = f.status ? 0 : 1;
        return f;
      }
      return f;
    });
    setPacientes(t);
  };

  useEffect(() => {
    clearForm();
    getData();
    const { editPowerUser } = state;
    setStatus(editPowerUser?.status === 1);
    setGroup(editPowerUser?.groupName);
    console.log(state);

    return () => {
      dispatch({
        type: 'set-citasTable',
        value: [],
      });
    };
  }, []);

  return (
    <>
      <Dialog
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">Borrar Usuario</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ⚠️ Atención, estos cambios no son reversibles
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertDialog(false)} color="primary">
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              disabled={loading}
              onClick={() => {
                setLoading(true);
                deleteData({
                  Username: state.editPowerUser?.Username,
                });
              }}
              color="secondary"
              autoFocus
            >
              Borrar
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={isMobile}
        open={state.dialogPowerUser}
        onClose={() => {
          dispatch({
            type: 'set-dialogPowerUser',
            value: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              {state?.editPowerUser?.email}
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogPowerUser',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Cambios de grupo de seguridad requieren
            que el usuario cierre la sesión e ingrese de nuevo a la aplicación.
          </DialogContentText>
          <Grid container className={classes.root} spacing={2}>

            <Grid item xs={12} sm={9} md={9}>
              <TextField
                id="security-group"
                select
                fullWidth
                label="Grupo De Seguridad"
                value={group}
                onChange={(e) => setGroup(e.target.value)}
              >
                {groups.map((option) => (
                  <MenuItem key={option.value} value={option.GroupName}>
                    {option.Description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <FormControlLabel
                control={<Switch checked={status} onChange={(e) => setStatus(e.target.checked)} name="Status" />}
                label="Habilitado"
              />
            </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              color="secondary"
              disabled={loading}
              onClick={() => {
                setAlertDialog(true);
              }}
            >
              Borrar Usuario
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
          <Button
            onClick={() => {
              clearForm();
              dispatch({
                type: 'set-dialogPowerUser',
                value: false,
              });
            }}
          >
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                uploadData({
                  Username: state.editPowerUser?.Username,
                  oldGroup: state.editPowerUser?.groupName,
                  email: state.editPowerUser?.email,
                  status: status ? 1 : 0,
                  groupName: group,
                  familyName: state.editPowerUser?.familyName,
                  givenName: state.editPowerUser?.givenName,
                });
              }}
            >
              Guardar
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogPowerUser;

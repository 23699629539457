/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  LinearProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Chip,
  Divider,
  ListItemIcon,
  TextField,
  FormControlLabel,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import {
  Close,
  Person,
  Group,
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function DialogEditGroup(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Form
  const [groupName, setGroupName] = useState('');
  // Error
  const [groupNameError, setGroupNameError] = useState(false);

  // Selected
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Citas
  const [valid, setValid] = useState([]);
  const [all, setAll] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  // Alert Dialog
  const [alertDialog, setAlertDialog] = useState(false);

  const getGroupData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    setLoading(true);
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes/groups',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      dispatch({
        type: 'set-pacienteGrpups',
        value: result.Items,
      });
    }
  };

  const uploadData = async (data) => {
    setLoading(true);
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'post',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes/groups',
      headers: { Authorization: token },
      data,
    }).then((res) => res.data).catch((err) => err);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Base De Datos Actualizada',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    setLoading(false);
    dispatch({
      type: 'set-dialogEditGroup',
      value: false,
    });
    getGroupData();
  };
  const deleteData = async () => {
    setLoading(true);
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'delete',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes/groups',
      headers: { Authorization: token },
      params: {
        accountEmail: state.editPatientGroupInfo.accountEmail,
        groupId: state.editPatientGroupInfo.groupId,
      },
    }).then((res) => res.data).catch((err) => err);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Base De Datos Actualizada',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    setLoading(false);
    dispatch({
      type: 'set-dialogEditGroup',
      value: false,
    });
    getGroupData();
  };

  const clearForm = () => {
    setGroupName('');
    setSelectedUsers([]);
  };
  const setSelected = (e) => {
    setValid([e]);
    setShowSelect(false);
  };
  const verifyAgainst = (e, value, setError) => {
    const validation = e === value || e === undefined || e === null;
    setError(validation);
    return validation;
  };
  const valdiateData = () => {
    const err = !![
      verifyAgainst(groupName, '', setGroupNameError),
    ].filter((e) => e === true).length;

    if (!err) {
      setLoading(true);
      uploadData({
        accountEmail: state.editPatientGroupInfo.accountEmail,
        groupId: state.editPatientGroupInfo.groupId,
        groupName,
        members: selectedUsers,
      });
    }
  };
  useEffect(() => {
    clearForm();
    console.log(state.editPatientGroupInfo);
    setGroupName(state.editPatientGroupInfo.groupName);
    setSelectedUsers(state.editPatientGroupInfo.members || []);
  }, []);

  return (
    <>
      <Dialog
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">Borrar Usuario</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ⚠️ Atención, estos cambios no son reversibles
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertDialog(false)} color="primary">
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              disabled={loading}
              onClick={() => {
                setLoading(true);
                deleteData();
              }}
              color="secondary"
              autoFocus
            >
              Borrar
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={isMobile}
        open={state.dialogEditGroup}
        onClose={() => {
          dispatch({
            type: 'set-dialogEditGroup',
            value: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              Editar Grupo
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogEditGroup',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ℹ️ Grupos a partir de 20 personas tendrán el beneficio de elegir la ubicación de toma de muestras o aplicación de vacunas.
          </DialogContentText>
          <Grid container className={classes.root} spacing={2}>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                margin="dense"
                error={groupNameError}
                id="name"
                label="Nombre Del Grupo"
                type="text"
                fullWidth
                value={groupName}
                onChange={(e) => {
                  setGroupNameError(false);
                  setGroupName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="subtitle2" gutterBottom>
                Seleccione Los Miembros Del Grupo
              </Typography>
            </Grid>
            {
              state.pacientes.map((e, i) => (
                <Grid key={`select_paciente_${i}`} item xs={12} sm={6} md={6}>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={selectedUsers.includes(e.pacienteId)}
                        onChange={(p) => {
                          const { checked, name } = p.target;
                          const pacienteId = parseInt(name, 10);
                          if (checked) {
                            const t = [...selectedUsers];
                            t.push(pacienteId);
                            setSelectedUsers(t);
                          } else {
                            const t = [...selectedUsers];
                            setSelectedUsers(t.filter((f) => f !== pacienteId));
                          }
                        }}
                        name={e.pacienteId}
                        color="secondary"
                      />
              )}
                    label={`${e.name} ${e.lastName}`}
                  />
                </Grid>
              ))
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setAlertDialog(true)}
          >
            Borrar Grupo
          </Button>
          <Button
            onClick={() => {
              dispatch({
                type: 'set-dialogEditGroup',
                value: false,
              });
            }}
            color="primary"
          >
            Cerrar
          </Button>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              disabled={loading}
              onClick={() => {
                valdiateData();
              }}
            >
              Guardar
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogEditGroup;

/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, {
  useContext, useState, useEffect, useRef, forwardRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  LinearProgress,
  Chip,
} from '@material-ui/core';
import {
  Room,
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Refresh,
  Email,
  Add,
} from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import MaterialTable from 'material-table';
// Components
import DialogPowerUser from '../components/DialogPowerUser';
import DialogCreatePowerUser from '../components/DialogCreatePowerUser';

// Store
import { store } from '../state/store';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function PowerUsers() {
  const classes = useStyles();

  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // table
  const [tableData, setTableData] = useState([]);

  const getData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/powerusers',
      headers: { Authorization: token },
    }).then((res) => res.data).catch((err) => err);
    console.log(result.Items);
    setLoading(false);
    setSuccess(true);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Datos Actualizados',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    dispatch({
      type: 'set-citasTable',
      value: result.Items,
    });
    dispatch({
      type: 'set-loading',
      value: false,
    });
  };

  useEffect(() => {
    dispatch({
      type: 'set-title',
      value: 'Power Users',
    });
    dispatch({
      type: 'set-loading',
      value: true,
    });
    getData();
    return () => {
      console.log('ON UNLOAD');
      dispatch({
        type: 'set-citasTable',
        value: [],
      });
    };
  }, []);

  return (
    <>

      {
        state.dialogPowerUser ? <DialogPowerUser /> : null
      }

      {
        state.dialogCreatePowerUser ? <DialogCreatePowerUser /> : null
      }
      <Container className={classes.cardGrid} maxWidth="lg">
        {state?.loading ? <LinearProgress /> : null}
        <MaterialTable
          icons={tableIcons}
          localization={{
            pagination: {
              firstAriaLabel: 'Primera página',
              firstTooltip: 'Primera página',
              previousAriaLabel: 'Pagina anterior',
              previousTooltip: 'Pagina anterior',
              nextAriaLabel: 'Siguiente página',
              nextTooltip: 'Siguiente página',
              lastAriaLabel: 'Última página',
              lastTooltip: 'Última página',
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'Filas',
              labelRowsPerPage: 'Filas por página:',
            },
            toolbar: {
              nRowsSelected: '{0} fila(s) seleccionadas',
              searchTooltip: 'Buscar',
              searchPlaceholder: 'Buscar',
              exportTitle: 'Exportar',
              exportAriaLabel: 'Exportar',
              exportName: 'Exportar como CSV',
              exportCSVName: 'Exportar como CSV',
              exportPDFName: 'Exportar como PDF',
            },
            header: {
              actions: 'Aciones',
            },
            body: {
              emptyDataSourceMessage: 'No hay registros que mostrar',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
            },
          }}
          options={{
            exportButton: true,
          }}
          columns={[
            { title: 'Cuenta', field: 'email' },
            { title: 'Nombre', field: 'givenName' },
            { title: 'Apellido', field: 'familyName' },
            { title: 'Grupo', field: 'groupName' },
            {
              title: 'Estatus',
              field: 'status',
              render: (rowData) => <Chip label={rowData?.status === 1 ? 'Habilitado' : 'Deshabilitado'} color={rowData?.status === 1 ? 'default' : 'secondary'} size="small" variant="outlined" />,
            },
          ]}
          data={state?.citasTable}
          title="Lista De Usuarios"
          actions={[
            state.groups.includes('Administradores')
              ? {
                icon: Edit,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  dispatch({
                    type: 'set-dialogPowerUser',
                    value: true,
                  });
                  dispatch({
                    type: 'set-editPowerUser',
                    value: rowData,
                  });
                // alert(`You saved ${rowData.citaId}`);
                },
              } : null,
            state.groups.includes('Administradores')
              ? {
                icon: Add,
                tooltip: 'Crear Usuario',
                isFreeAction: true,
                onClick: () => {
                  dispatch({
                    type: 'set-dialogCreatePowerUser',
                    value: true,
                  });
                },
              }
              : null,
            {
              icon: Refresh,
              tooltip: 'Actualizar datos',
              isFreeAction: true,
              onClick: () => getData(),
            },
          ]}
        />
      </Container>
    </>
  );
}

export default PowerUsers;
// state.groups.includes('Administradores')

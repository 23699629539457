/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, {
  useContext, useState, useEffect, useRef, forwardRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Link,
  CssBaseline,
  TextField,
  MenuItem,
  Button,
  LinearProgress,
  Chip,
} from '@material-ui/core';
import { // INCONS
  Room,
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Refresh,
  Email,
} from '@material-ui/icons';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import MaterialTable from 'material-table';
import DayjsUtils from '@date-io/dayjs';
import esLocale from 'dayjs/locale/es';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

// Components

import dayjs from 'dayjs';
import DialogContact from '../components/DialogContact';
import DialogPowerUserCita from '../components/DialogPowerUserCita';
import DialogPowerUserPatient from '../components/DialogPowerUserPatient';

// Store
import { store } from '../state/store';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Reporting() {
  const classes = useStyles();

  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedReport, setSelectedReport] = useState(false);

  const [reportFrom, setReportFrom] = useState();
  const [reportTo, setReportTo] = useState();

  const [scanType, setScanType] = useState('date');

  // table
  const [tableData, setTableData] = useState([]);

  const reportes = [
    {
      value: 'users',
      label: 'Usuarios',
      key: 'createdDate',
      values: 'accountEmail,cellphone,createdDate,lastName,name',
      columns: [
        { title: 'Cuenta', field: 'accountEmail' },
        { title: 'Teléfono', field: 'cellphone' },
        {
          title: 'Creada',
          field: 'createdDate',
          render: (rowData) => dayjs(rowData?.createdDate || dayjs().valueOf()).format('DD/MM/YYYY hh:mm A'),
          type: 'datetime',
        },
        { title: 'Nombres', field: 'name' },
        { title: 'Apellidos', field: 'lastName' },
      ],
      actions: [
        {
          icon: Email,
          tooltip: 'Contactar',
          onClick: (event, rowData) => {
            dispatch({
              type: 'set-dialogContact',
              value: true,
            });
            dispatch({
              type: 'set-editContact',
              value: {
                email: rowData.accountEmail,
                phone: rowData.cellphone,
              },
            });
          // alert(`You saved ${rowData.citaId}`);
          },
        },
      ],
    },
    {
      value: 'groups',
      label: 'Grupos',
      key: 'createdDate',
      values: 'accountEmail,groupId,groupName,members',
      columns: [
        { title: 'Cuenta', field: 'accountEmail' },
        {
          title: 'Creada',
          field: 'groupId',
          render: (rowData) => dayjs(rowData?.groupId || dayjs().valueOf()).format('DD/MM/YYYY hh:mm A'),
          type: 'datetime',
        },
        { title: 'Nombre', field: 'groupName' },
        {
          title: 'Miembros',
          field: 'members',
          render: (rowData) => rowData?.members.length,
          type: 'numeric',
        },
      ],
    },
    {
      value: 'citas',
      label: 'Citas',
      key: 'citaId',
      values: 'accountEmail,address,cita,citaId,created,email,pacientes,pago,cellphone,place,products,status,updated',
      columns: [
        { title: 'Cuenta', field: 'accountEmail' },
        {
          title: 'Creada',
          field: 'citaId',
          render: (rowData) => dayjs(rowData?.citaId).format('DD/MM/YYYY hh:mm A'),
          type: 'datetime',
        },
        {
          title: 'Actualizada',
          field: 'updated',
          render: (rowData) => (rowData?.updated ? dayjs(rowData?.updated).format('DD/MM/YYYY hh:mm A') : null),
          type: 'datetime',
        },
        {
          title: 'Productos',
          field: 'products',
          render: (rowData) => rowData?.products.map((e) => e.title).join(),
        },
        {
          title: 'Pacientes',
          field: 'pacientes',
          render: (rowData) => rowData?.pacientes.length || 0,
          type: 'numeric',
        },
        {
          title: 'Pendientes',
          field: 'pacientes',
          render: (rowData) => rowData?.pacientes.filter((e) => e.status === 0).length,
          type: 'numeric',
        },
        {
          title: 'Dosis',
          field: 'dosis',
          render: (rowData) => rowData?.dosis || 0,
          type: 'numeric',
        },
        {
          title: 'Cita',
          field: 'cita',
          render: (rowData) => (rowData?.cita ? dayjs(rowData?.cita).format('DD/MM/YYYY hh:mm A') : null),
          type: 'datetime',
        },
        {
          title: 'Pago',
          field: 'pago',
          render: (rowData) => <Chip variant="outlined" color={rowData?.pago === 1 ? 'secondary' : ''} label={rowData?.pago === 1 ? 'Completado' : 'Pendiente'} />,

        },
        {
          title: 'Estatus',
          field: 'status',
          render: (rowData) => <Chip variant="outlined" color={rowData?.status === 1 ? 'secondary' : ''} label={rowData?.status === 1 ? 'Completado' : 'Pendiente'} />,
        },
      ],
      actions: [
        {
          icon: Edit,
          tooltip: 'Editar',
          onClick: (event, rowData) => {
            dispatch({
              type: 'set-dialogPowerUserCita',
              value: true,
            });
            dispatch({
              type: 'set-editCitaInfo',
              value: rowData,
            });
            // alert(`You saved ${rowData.citaId}`);
          },
        },
        {
          icon: Email,
          tooltip: 'Contactar',
          onClick: (event, rowData) => {
            dispatch({
              type: 'set-dialogContact',
              value: true,
            });
            dispatch({
              type: 'set-editContact',
              value: {
                email: rowData.accountEmail,
                phone: rowData.cellphone,
              },
            });
            // alert(`You saved ${rowData.citaId}`);
          },
        },
      ],
    },
    {
      value: 'pacientes',
      label: 'Pacientes',
      key: 'pacienteId',
      values: 'accountEmail,pacienteId,dob,name,lastName,sex',
      columns: [
        { title: 'Cuenta', field: 'accountEmail' },
        { title: 'Fec. Nac.', field: 'dob', type: 'date' },
        { title: 'Nombre', field: 'name' },
        { title: 'Apellido', field: 'lastName' },
        { title: 'Sexo', field: 'sex' },
      ],
      actions: [
        {
          icon: Edit,
          tooltip: 'Editar',
          onClick: (event, rowData) => {
            dispatch({
              type: 'set-editPowerUserPatientInfo',
              value: rowData,
            });
            dispatch({
              type: 'set-dialogPowerUserPatientInfo',
              value: true,
            });
            // alert(`You saved ${rowData.citaId}`);
          },
        },
      ],
    },
  ];

  const getAdditionalData = async (key, items) => {
    dispatch({
      type: 'set-loading',
      value: true,
    });
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/reports',
      headers: { Authorization: token },
      params: {
        from: reportFrom.startOf('day').valueOf(),
        to: reportTo.endOf('day').valueOf(),
        table: selectedReport,
        key: reportes.find((e) => e.value === selectedReport).key,
        values: reportes.find((e) => e.value === selectedReport).values,
        scan: scanType,
        lastEvaluatedKey: JSON.stringify(key),
      },
    }).then((res) => res.data).catch((err) => err);
    console.log(result.Items.length);
    const resultItems = items.concat(result.Items);
    // eslint-disable-next-line no-prototype-builtins
    if (result.hasOwnProperty('LastEvaluatedKey')) {
      getAdditionalData(result.LastEvaluatedKey, resultItems);
    } else {
      dispatch({
        type: 'set-citasTable',
        value: resultItems,
      });
      dispatch({
        type: 'set-notificationMessage',
        value: 'Datos Actualizados',
      });
      dispatch({
        type: 'set-notification',
        value: true,
      });
      setLoading(false);
      setSuccess(true);
      dispatch({
        type: 'set-loading',
        value: false,
      });
    }
  };
  const getData = async () => {
    dispatch({
      type: 'set-loading',
      value: true,
    });
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/reports',
      headers: { Authorization: token },
      params: {
        from: reportFrom.startOf('day').valueOf(),
        to: reportTo.endOf('day').valueOf(),
        table: selectedReport,
        key: reportes.find((e) => e.value === selectedReport).key,
        values: reportes.find((e) => e.value === selectedReport).values,
        scan: scanType,
      },
    }).then((res) => res.data).catch((err) => err);
    console.log(result.Items);

    // eslint-disable-next-line no-prototype-builtins
    if (result.hasOwnProperty('LastEvaluatedKey')) {
      getAdditionalData(result.LastEvaluatedKey, result.Items);
    } else {
      dispatch({
        type: 'set-citasTable',
        value: result.Items,
      });

      dispatch({
        type: 'set-notificationMessage',
        value: 'Datos Actualizados',
      });
      dispatch({
        type: 'set-notification',
        value: true,
      });
      setLoading(false);
      setSuccess(true);
      dispatch({
        type: 'set-loading',
        value: false,
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'set-title',
      value: 'Reportes',
    });
    setReportFrom(dayjs());
    setReportTo(dayjs());
    setSelectedReport(reportes[0].value);
    return () => {
      dispatch({
        type: 'set-citasTable',
        value: [],
      });
    };
  }, []);

  return (
    <>
      {
        state.dialogPowerUserCita ? <DialogPowerUserCita /> : null
      }
      {
        state.dialogPowerUserPatientInfo ? <DialogPowerUserPatient /> : null
      }
      {
        state.dialogContact ? <DialogContact /> : null
      }
      <Container className={classes.cardGrid} maxWidth="lg">
        <Grid
          container
          spacing={2}
          justify="flex-end"
          alignItems="center"
        >
          {
  scanType === 'date' ? (
    <>
      <Grid item xs={2}>
        <MuiPickersUtilsProvider utils={DayjsUtils} locale={esLocale}>
          <DatePicker
            disableFuture
            fullWidth
            cancelLabel="Cancelar"
            okLabel="Aceptar"
            openTo="date"
            format="DD/MM/YYYY"
            label="Desde"
            views={['year', 'month', 'date']}
            value={reportFrom}
            onChange={(e) => {
              setReportFrom(e);
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2}>
        <MuiPickersUtilsProvider utils={DayjsUtils} locale={esLocale}>
          <DatePicker
                // error={dobError}
            disableFuture
            fullWidth
            cancelLabel="Cancelar"
            okLabel="Aceptar"
            openTo="date"
            format="DD/MM/YYYY"
            label="A"
            views={['year', 'month', 'date']}
            value={reportTo}
            onChange={(e) => {
              setReportTo(e);
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </>
  ) : null
}
          <Grid item xs={2}>
            <TextField
              id="select-table-report"
              fullWidth
              select
              label="Tipo"
              defaultValue="Citas"
              value={scanType}
              onChange={(e) => {
                console.log(e.target.value);
                setScanType(e.target.value);
              }}
            >
              <MenuItem value="date">
                Fecha
              </MenuItem>
              <MenuItem value="scan">
                Tabla
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select-table-report"
              fullWidth
              select
              label="Reporte"
              defaultValue="Citas"
              value={selectedReport}
              onChange={(e) => {
                dispatch({
                  type: 'set-citasTable',
                  value: [],
                });
                setSelectedReport(e.target.value);
              }}
            >
              {
                reportes.map((e) => (
                  <MenuItem key={e.value} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))
              }
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <Button fullWidth variant="contained" onClick={() => getData()}>Consultar</Button>
          </Grid>
          <Grid item xs={12}>
            {state?.loading ? <LinearProgress /> : null}
            <MaterialTable
              icons={tableIcons}
              localization={{
                pagination: {
                  firstAriaLabel: 'Primera página',
                  firstTooltip: 'Primera página',
                  previousAriaLabel: 'Pagina anterior',
                  previousTooltip: 'Pagina anterior',
                  nextAriaLabel: 'Siguiente página',
                  nextTooltip: 'Siguiente página',
                  lastAriaLabel: 'Última página',
                  lastTooltip: 'Última página',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  labelRowsSelect: 'Filas',
                  labelRowsPerPage: 'Filas por página:',
                },
                toolbar: {
                  nRowsSelected: '{0} fila(s) seleccionadas',
                  searchTooltip: 'Buscar',
                  searchPlaceholder: 'Buscar',
                  exportTitle: 'Exportar',
                  exportAriaLabel: 'Exportar',
                  exportName: 'Exportar como CSV',
                  exportCSVName: 'Exportar como CSV',
                  exportPDFName: 'Exportar como PDF',
                },
                header: {
                  actions: 'Aciones',
                },
                body: {
                  emptyDataSourceMessage: 'No hay registros que mostrar',
                  filterRow: {
                    filterTooltip: 'Filtrar',
                  },
                },
              }}
              options={{
                pageSizeOptions: [5, 10, 50, 100, 1000],
                exportButton: true,
              }}
              columns={reportes.find((e) => e.value === selectedReport)?.columns || []}
              data={state?.citasTable}
              title={reportes.find((e) => e.value === selectedReport)?.label}
              actions={reportes.find((e) => e.value === selectedReport)?.actions || []}
            />
          </Grid>
        </Grid>

      </Container>
    </>
  );
}

export default Reporting;

/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  Grow,
  Fade,
  Paper,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  ListItemIcon,
  ListItem,
  List,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import esLocale from 'dayjs/locale/es';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  PhotoCamera, Close, Comment, NavigateNext,
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components
import CardPaciente from './CardPaciente';
import DialogPowerUserPatient from './DialogPowerUserPatient';
import CardCita from './CardCita';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const sexChoices = [{
  value: 'masculino',
  label: 'Masculino',
},
{
  value: 'femenino',
  label: 'Femenino',
}];

function getSteps() {
  return ['Información', 'Pacientes'];
}

function DialogPowerUserCita(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { editCitaInfo, user } = state;

  // Form Vars
  const [dob, setDOB] = useState();
  const [place, setPlace] = useState('');
  const [homeAddress, setHomeAddress] = useState('');

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // Error Form

  const [dobError, setDobError] = useState(false);
  const [placeError, setPlaceError] = useState(false);
  const [homeAddressError, setHomeAddressError] = useState(false);

  const [cita, setCita] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [service, setService] = useState('');
  const [placeChoices, setPlaceChoices] = useState([]);
  const [pacientes, setPacientes] = useState([]);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const verifyAgainst = (e, value, setError) => {
    const validation = e === value || e === undefined || e === null;
    setError(validation);
    return validation;
  };
  const verifyValues = () => {
    const validations = [];
    switch (activeStep) {
      case 0:
        // eslint-disable-next-line no-unused-expressions
        if (cita) {
          validations.push(verifyAgainst(place, '', setPlaceError));
          if (place === 'home' || place === 'custom') {
            validations.push(verifyAgainst(homeAddress, '', setHomeAddressError));
          }
          validations.push(verifyAgainst(place, '', setPlaceError));
        }
        // eslint-disable-next-line no-unused-expressions
        validations.filter((e) => e).length ? '' : handleNext();
        // [
        //   verifyAgainst(name, '', setNameError),
        //   verifyAgainst(lastName, '', setLastNameError),
        //   verifyAgainst(sex, '', setSexError),
        //   verifyAgainst(dob.format('DD/MM/YYYY'), dayjs().format('DD/MM/YYYY'), setDobError),
        //   verifyAgainst(birdPlace, '', setBirdPlaceError),
        // ].filter((e) => e === true).length ? '' : handleNext();
        break;
      default:
        handleNext();
        console.log('INVALID');
    }
  };
  const getTableData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/powerusers/citas',
      headers: { Authorization: token },
      params: {
        status: 0,
        pago: 1,
      },
    }).then((res) => res.data).catch((err) => err);
    console.log(result.Items);
    setLoading(false);
    setSuccess(true);
    dispatch({
      type: 'set-citasTable',
      value: result.Items,
    });
    dispatch({
      type: 'set-loading',
      value: false,
    });
  };
  const getData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/places',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      setPlaceChoices(result.Items);
    }
  };
  const uploadData = async (data) => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'put',
      url: `https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/citas/${data.citaId}`,
      headers: { Authorization: token },
      data,
    }).then((res) => res.data).catch((err) => err);

    const { accountEmail, cellphone } = editCitaInfo;
    const citaUpdate = editCitaInfo?.cita ? dayjs(editCitaInfo?.cita).valueOf() !== dob : dayjs().isSame(dayjs().valueOf(), 'day');
    const placeUpdate = place ? editCitaInfo?.place !== place : false;

    if (citaUpdate && placeUpdate && !(pacientes.filter((e) => e.status === 0).length ? 0 : 1)) {
      const msg = `Hola ${user.given_name}, tiene una cita programada para el ${dob.format('DD/MM/YYYY hh:mm A')}. En ${place === 'home' || place === 'custom' ? data.address : place}`;
      const message = {
        email: accountEmail,
        phone: cellphone,
        msg,
        sendEmail: true,
        sendSMS: true,
      };
      const notification = await axios({
        method: 'post',
        url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/contacto',
        headers: { Authorization: token },
        data: message,
      }).then((res) => res.data).catch((err) => err);
      console.log(notification);
    } else if (citaUpdate && !(pacientes.filter((e) => e.status === 0).length ? 0 : 1)) {
      const msg = `Hola ${user.given_name} su cita será reagendada para el ${dob.format('DD/MM/YYYY hh:mm A')}.`;
      const message = {
        email: accountEmail,
        phone: cellphone,
        msg,
        sendEmail: true,
        sendSMS: true,
      };
      const notification = await axios({
        method: 'post',
        url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/contacto',
        headers: { Authorization: token },
        data: message,
      }).then((res) => res.data).catch((err) => err);
      console.log(notification);
    }

    console.log(result);
    setLoading(false);
    setSuccess(true);
    dispatch({
      type: 'set-loading',
      value: false,
    });
    dispatch({
      type: 'set-dialogPowerUserCita',
      value: false,
    });
    getTableData();
  };

  const clearForm = () => {
    setDOB(dayjs());
    setLoading(false);
    setSuccess(false);
    setDobError(false);
    setHomeAddress('');
    setHomeAddressError(false);
    setPlaceError(false);
    setCita(false);
    setOpenDate(false);
    setService('');
    setPlace('');
    setPlaceChoices([]);
    setPacientes([]);
    setActiveStep(0);
  };

  // const getProps = () => {
  //   const r = {
  //     cita: cita ? dob : false,
  //     status: !pacientes.filter((e) => e.status === 0).length,
  //     pago: editCitaInfo?.pago,
  //     pacientes,
  //     products: editCitaInfo?.products,
  //   };
  //   return r;
  // };

  const updatePacient = (e) => {
    console.log(e);
    const p = [...pacientes];
    const t = p.map((f) => {
      if (f.pacienteId === e.pacienteId) {
        // eslint-disable-next-line no-param-reassign
        f.status = f.status ? 0 : 1;
        return f;
      }
      return f;
    });
    setPacientes(t);
  };

  const getProps = () => ({ ...editCitaInfo, ...{ place: `${place === 'home' || place === 'custom' ? editCitaInfo.address : place}`, status: pacientes.filter((e) => e.status === 0).length ? 0 : 1, coordinates: place === 'home' || place === 'custom' ? 0 : placeChoices.find((e) => e.name === place).coordinates } });

  useEffect(() => {
    clearForm();
    getData();

    console.log('%%%%POWERUSERDIALOG%%');
    console.log(editCitaInfo);
    setService(editCitaInfo?.servicio);
    setCita(editCitaInfo?.cita);
    setDOB(editCitaInfo?.cita ? dayjs(editCitaInfo?.cita) : dayjs());
    setPlace(editCitaInfo?.place);
    setHomeAddress(editCitaInfo?.address);
    setPacientes(editCitaInfo.pacientes);

    return () => {
      // dispatch({
      //   type: 'set-citasTable',
      //   value: [],
      // });
    };
  }, []);

  return (
    <>

      {
        state.dialogPowerUserPatientInfo ? <DialogPowerUserPatient /> : null
      }
      <Dialog
        fullScreen={isMobile}
        open={state.dialogPowerUserCita}
        onClose={() => {
          dispatch({
            type: 'set-dialogPowerUserCita',
            value: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              Cita
              {' '}
              {service}
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogPowerUserCita',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {
          activeStep === 0 ? (
            <>
              <Fade in={activeStep === 0}>
                <Paper elevation={0}>
                  {
                    cita
                      ? (
                        <DialogContentText>
                          Los cambios de cita notifican al paciente de manera automática.
                        </DialogContentText>
                      )
                      : (
                        <DialogContentText>
                          Cita no asignada,
                          una vez que se asigne una fecha y lugar
                          se le enviara de manera automática una notificación al paciente.
                        </DialogContentText>
                      )
                  }
                  <Grid container className={classes.root} spacing={2}>
                    {
                      !cita ? (
                        <Grid item xs={12} sm={12} md={12}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              setOpenDate(true);
                              setCita(true);
                            }}
                          >
                            Asignar cita
                          </Button>
                        </Grid>
                      ) : null
                    }
                    {
                      cita ? (
                        <>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              required
                              error={placeError}
                              select
                              id="place"
                              label="Lugar"
                              type="text"
                              fullWidth
                              value={place}
                              onChange={(e) => {
                                setPlace(e.target.value);
                                setPlaceError(false);
                                if (place === 'home' || place === 'custom') {
                                  setHomeAddress('');
                                }
                              }}
                            >
                              {placeChoices.map((option, i) => (
                                <MenuItem key={`${option.name}_i`} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                              {/* <MenuItem value="home">
                                Domicilio de paciente
                              </MenuItem> */}
                              <MenuItem value="custom">
                                Personalizado
                              </MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <MuiPickersUtilsProvider utils={DayjsUtils} locale={esLocale}>
                              <DateTimePicker
                                required
                                open={openDate}
                                onOpen={() => {
                                  console.log('onOpen');
                                  setOpenDate(true);
                                }}
                                onClose={() => {
                                  console.log('onClose');
                                  setOpenDate(false);
                                  // TODO
                                  // eslint-disable-next-line max-len
                                  // if (homeAddress === '' && dob.format('YYYY-MM-DDTHH:mm') === dayjs().format('YYYY-MM-DDTHH:mm')) {
                                  //   setOpenDate(false);
                                  //   setCita(false);
                                  // }
                                }}
                                error={dobError}
                                disablePast
                                fullWidth
                                cancelLabel="Cancelar"
                                okLabel="Aceptar"
                                openTo="date"
                                format="DD/MM/YYYY hh:mm A"
                                label="Fecha y Hora"
                          // views={['year', 'month', 'date']}
                                value={dob}
                                onChange={(e) => {
                                  setDobError(false);
                                  setDOB(e);
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </>
                      ) : null
                    }

                    {
                      place === 'home' || place === 'custom' ? (
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            required
                            margin="dense"
                            error={homeAddressError}
                            id="homeAddress"
                            label={place === 'home' ? 'Domicilio Paciente' : 'Lugar y Domicilio'}
                            type="text"
                            fullWidth
                            value={homeAddress}
                            onChange={(e) => {
                              setHomeAddress(e.target.value);
                              setHomeAddressError(false);
                            }}
                          />
                        </Grid>
                      ) : null
                    }

                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}
          {
          activeStep === 1 ? (
            <>
              <Fade in={activeStep === 1}>
                <Paper elevation={0}>
                  <DialogContentText>
                    Lista de pacientes vacunados y
                    su información personal
                  </DialogContentText>
                  <List className={classes.root}>
                    {
                    pacientes.map((e, i) => (
                      <ListItem
                        key={`${e.pacientId}_${i}`}
                        role={undefined}
                        dense
                        button
                        onClick={() => updatePacient(e)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={e.status}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': e.pacientId }}
                          />

                        </ListItemIcon>
                        <ListItemText
                          id={e.pacientId}
                          primary={(
                            <>
                              {e?.name}
                              {' '}
                              {e?.lastName}
                            </>
                          )}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={() => {
                              dispatch({
                                type: 'set-editPowerUserPatientInfo',
                                value: {
                                  pacienteId: e.pacienteId,
                                  name: e.name,
                                  lastName: e.lastName,
                                  accountEmail: editCitaInfo?.accountEmail, // https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes
                                },
                              });
                              dispatch({
                                type: 'set-dialogPowerUserPatientInfo',
                                value: true,
                              });
                            }}
                          >
                            <NavigateNext />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))
                    }
                  </List>
                </Paper>
              </Fade>
            </>
          ) : null
}

          {
activeStep === steps.length ? (
  <>
    <Fade in={activeStep === steps.length}>
      <Paper elevation={0} sytle={{ width: '100%' }}>
        <DialogContentText>
          Al guardar los cambios,
          si se detecta un cambio o asignación de cita se le notificará de
          manera automática al paciente.
        </DialogContentText>
        <CardCita {...getProps()} />
      </Paper>
    </Fade>
  </>
) : null
}

        </DialogContent>
        <DialogActions>

          {activeStep === steps.length ? (
            <>
              <Button
                onClick={() => {
                  clearForm();
                  dispatch({
                    type: 'set-dialogPowerUserCita',
                    value: false,
                  });
                }}
                // color="secondary"
              >
                Cancelar
              </Button>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);

                    console.log(cita ? dob : false);
                    console.log(cita ? typeof dob !== 'string' : false, 'CITA');/// New Cita
                    console.log(place ? state?.editCitaInfo?.place !== place : false, 'PLACE');
                    uploadData({
                      ...editCitaInfo,
                      ...{
                        updated: dayjs().valueOf(),
                        place,
                        pacientes,
                        address: homeAddress,
                        cita: cita ? dob.valueOf() : 0,
                        status: pacientes.filter((e) => e.status === 0).length ? 0 : 1,
                        name: user.given_name,
                        coordinates: place === 'home' || place === 'custom' ? 0 : placeChoices.find((e) => e.name === place).coordinates,
                      },
                    });
                  }}
                >
                  Guardar
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </>
          ) : (
            <>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                // color="primary"
              >
                Regresar
              </Button>
              <Button
                onClick={verifyValues}
                color="primary"
              >
                Siguiente
              </Button>
            </>
          )}

        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogPowerUserCita;

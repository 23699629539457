/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Fab,
  CircularProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { Add, MoreHoriz } from '@material-ui/icons';
import axios from 'axios';
// Components
import CardCita from '../components/CardCita';
import DialogApointment from '../components/DialogApointment';

// Store
import { store } from '../state/store';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  media: {
    height: 160,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    // color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
}));

function Apointment() {
  const classes = useStyles();

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { citas } = state;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const getData = async () => {
    setLoading(true);
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/citas',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      dispatch({
        type: 'set-notificationMessage',
        value: 'Datos Actualizados',
      });
      dispatch({
        type: 'set-notification',
        value: true,
      });
      dispatch({
        type: 'set-citas',
        value: result.Items.sort((a, b) => b.citaId - a.citaId),
      });
    }
    setLoading(false);
  };

  const getProps = (e) => ({ ...e, ...{ place: `${e?.place === 'home' || e?.place === 'custom' ? e?.address : e?.place}` } });

  useEffect(() => {
    getData();
    dispatch({
      type: 'set-title',
      value: 'Tu Cita',
    });
  }, []);

  return (
    <>

      {
        state.dialogNewApointment ? <DialogApointment /> : null
      }
      <>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <Grid container justify="center" alignItems="center">
                <Grid item>
                  <div className={classes.wrapper}>
                    <Tooltip title="Crear Nueva Cita">
                      <Fab
                        color={loading ? 'default' : 'primary'}
                        aria-label="add"
                        onClick={() => {
                          dispatch({
                            type: 'set-dialogNewApointment',
                            value: true,
                          });
                        }}
                      >
                        { loading ? <MoreHoriz /> : <Add />}
                      </Fab>
                    </Tooltip>
                    {loading && <CircularProgress size={68} className={classes.fabProgress} color={loading ? 'default' : 'primary'} />}
                  </div>
                </Grid>
              </Grid>
            </Grid> */}
            {
              citas.length ? citas.map((e, i) => (
                <Grid key={`registro_${i}`} item xs={12} sm={6} md={6}>
                  <CardCita {...getProps(e)} edit />
                </Grid>
              )) : (
                <Grid container justify="center" alignItems="center">
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography variant="h6" align="center" gutterBottom>
                      ⚠️No hay registros que mostrar
                    </Typography>
                  </Grid>
                </Grid>
              )
            }
          </Grid>
        </Container>
      </>
    </>
  );
}

export default Apointment;

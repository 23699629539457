/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
// store.js
import React, { createContext, useReducer } from 'react';

const initialState = {
  title: 'Main',
  page: 'Home',
  accessToken: '',
  refreshToken: '',
  loading: false,
  dialogPowerUserCreateCita: false,
  dialogQRScanner: false,
  dialogEditGroup: false,
  dialogNewGroup: false,
  dialogNewUserGroup: false,
  dialogCreatePowerUser: false,
  dialogCitasActive: false,
  dialogQR: false,
  dialogNewPatient: false,
  dialogEditPatient: false,
  dialogNewApointment: false,
  dialogPowerUserCita: false,
  dialogContact: false,
  dialogPowerUserPatientInfo: false,
  editPowerUserPatientInfo: {},
  dialogPowerUser: false,
  editPatientGroupInfo: {},
  editPatientGroup: false,
  dialogPayCita: false,
  payCitaId: '',
  editPowerUser: {},
  editCitaInfo: {},
  editPatientInfo: {},
  editContact: {},
  users: [],
  pacientes: [],
  pacienteGrpups: [],
  citas: [],
  vacunas: [],
  citasTable: [],
  newCitasTable: [],
  servicios: [],
  accountEmail: '',
  user: {},
  groups: [],
  notification: false,
  notificationMessage: '',
  progress: 100,
  ws: null,
  welcomeDialog: true, // true
  status: false, // false
  strings: {},
  password: '',
  name: 'Player',
  server: 'wss://',
  servers: [],
  emoji: {
    id: 'sunglasses',
    name: 'Smiling Face with Sunglasses',
    native: '😎',
  },
  battery: -1,
  messageQueue: [],
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'set-vacunas':
        return { ...state, ...{ vacunas: action.value } };
      case 'set-dialogPayCita':
        return { ...state, ...{ dialogPayCita: action.value } };
      case 'set-payCitaId':
        return { ...state, ...{ payCitaId: action.value } };
      case 'set-dialogPowerUserCreateCita':
        return { ...state, ...{ dialogPowerUserCreateCita: action.value } };
      case 'set-dialogQRScanner':
        return { ...state, ...{ dialogQRScanner: action.value } };
      case 'set-editPatientGroup':
        return { ...state, ...{ editPatientGroup: action.value } };
      case 'set-editPatientGroupInfo':
        return { ...state, ...{ editPatientGroupInfo: action.value } };
      case 'set-dialogEditGroup':
        return { ...state, ...{ dialogEditGroup: action.value } };
      case 'set-pacienteGrpups':
        return { ...state, ...{ pacienteGrpups: action.value } };
      case 'set-dialogNewGroup':
        return { ...state, ...{ dialogNewGroup: action.value } };
      case 'set-dialogNewUserGroup':
        return { ...state, ...{ dialogNewUserGroup: action.value } };
      case 'set-dialogCreatePowerUser':
        return { ...state, ...{ dialogCreatePowerUser: action.value } };
      case 'set-dialogPowerUser':
        return { ...state, ...{ dialogPowerUser: action.value } };
      case 'set-editPowerUser':
        return { ...state, ...{ editPowerUser: action.value } };
      case 'set-notification':
        return { ...state, ...{ notification: action.value } };
      case 'set-notificationMessage':
        return { ...state, ...{ notificationMessage: action.value } };
      case 'set-loading':
        return { ...state, ...{ loading: action.value } };
      case 'set-newCitasTable':
        return { ...state, ...{ newCitasTable: action.value } };
      case 'set-citasTable':
        return { ...state, ...{ citasTable: action.value } };
      case 'set-groups':
        return { ...state, ...{ groups: action.value } };
      case 'set-users':
        return { ...state, ...{ users: action.value } };
      case 'set-accountEmail':
        return { ...state, ...{ accountEmail: action.value } };
      case 'set-user':
        return { ...state, ...{ user: action.value } };
      case 'set-accessToken':
        return { ...state, ...{ accessToken: action.value } };
      case 'set-refreshToken':
        return { ...state, ...{ refreshToken: action.value } };
      case 'set-title':
        return { ...state, ...{ title: action.value } };
      case 'set-page':
        return { ...state, ...{ page: action.value, citasTable: [] } };
      case 'set-citasActiveInfo':
        return { ...state, ...{ citasActiveInfo: action.value } };
      case 'set-dialogCitasActive':
        return { ...state, ...{ dialogCitasActive: action.value } };
      case 'set-dialogPowerUserPatientInfo':
        return { ...state, ...{ dialogPowerUserPatientInfo: action.value } };
      case 'set-editPowerUserPatientInfo':
        return { ...state, ...{ editPowerUserPatientInfo: action.value } };
      case 'set-dialogContact':
        return { ...state, ...{ dialogContact: action.value } };
      case 'set-dialogPowerUserCita':
        return { ...state, ...{ dialogPowerUserCita: action.value } };
      case 'set-dialogQR':
        return { ...state, ...{ dialogQR: action.value } };
      case 'set-dialogNewPatient':
        return { ...state, ...{ dialogNewPatient: action.value } };
      case 'set-dialogEditPatient':
        return { ...state, ...{ dialogEditPatient: action.value } };
      case 'set-dialogNewApointment':
        return { ...state, ...{ dialogNewApointment: action.value } };
      case 'set-editPatientInfo':
        return { ...state, ...{ editPatientInfo: action.value } };
      case 'set-editCitaInfo':
        return { ...state, ...{ editCitaInfo: action.value } };
      case 'set-editContact':
        return { ...state, ...{ editContact: action.value } };
      case 'set-pacientes':
        return { ...state, ...{ pacientes: action.value } };
      case 'set-citas':
        return { ...state, ...{ citas: action.value } };
      case 'set-servicios':
        return { ...state, ...{ servicios: action.value } };
      case 'set-password':
        return { ...state, ...{ password: action.value } };
      case 'set-strings':
        return { ...state, ...{ strings: action.value } };
      case 'set-servers':
        return { ...state, ...{ servers: action.value } };
      case 'set-progress':
        return { ...state, ...{ progress: action.value } };
      case 'set-ws':
        return { ...state, ...{ ws: action.value } };
      case 'set-status':
        return { ...state, ...{ status: action.value } };
      case 'set-battery':
        return { ...state, ...{ battery: action.value } };
      case 'set-emoji':
        return { ...state, ...{ emoji: action.value } };
      case 'set-server':
        return { ...state, ...{ server: action.value } };
      case 'set-name':
        return { ...state, ...{ name: action.value } };
      case 'set-welcome-dialog':
        return { ...state, ...{ welcomeDialog: action.value } };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };

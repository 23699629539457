/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  Grow,
  Fade,
  Paper,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import clsx from 'clsx';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import esLocale from 'dayjs/locale/es';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PhotoCamera, Close } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components
import CardPaciente from './CardPaciente';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const sexChoices = [{
  value: 'masculino',
  label: 'Masculino',
},
{
  value: 'femenino',
  label: 'Femenino',
}];

function getSteps() {
  return ['Información Personal', 'Vacunas', 'Foto'];
}

function DialogPowerUserPatient() {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Form Vars
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [sex, setSex] = useState('');
  const [dob, setDOB] = useState();
  const [image, setImage] = useState('');
  const [pacienteId, setPacienteId] = useState(0);
  const [birdPlace, setBirdPlace] = useState('');
  const [phone, setPhone] = useState('');
  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // Alert Dialog
  const [alertDialog, setAlertDialog] = useState(false);
  // Error Form
  const [nameError, setNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [sexError, setSexError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [birdPlaceError, setBirdPlaceError] = useState(false);
  const [editFields, setEditFields] = useState(true);

  const [confirmDate, setConfirmDate] = useState(false);
  const [selectedVacuna, setSelectedVacuna] = useState('');

  // Check Box
  const [vacunas, setVacunas] = useState([]);
  const [alergias, setAlergias] = useState('');

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const verifyAgainst = (e, value, setError) => {
    setError(e === value);
    return e === value;
  };
  const verifyValues = () => {
    switch (activeStep) {
      case 0:
        if (!editFields) {
          [
            verifyAgainst(name, '', setNameError),
            verifyAgainst(lastName, '', setLastNameError),
            verifyAgainst(sex, '', setSexError),
            verifyAgainst(dob.format('DD/MM/YYYY'), dayjs().format('DD/MM/YYYY'), setDobError),
          ].filter((e) => e === true).length ? '' : handleNext();
        } else {
          handleNext();
        }
        // eslint-disable-next-line no-unused-expressions

        break;
      default:
        handleNext();
        console.log('INVALID');
    }
  };

  const getData = async (e) => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: `https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes/${e}`,
      headers: { Authorization: token },
      params: {
        accountEmail: state.editPowerUserPatientInfo?.accountEmail,
        pacienteId: e,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      const usr = result?.Items[0];
      setName(usr?.name);
      setLastName(usr?.lastName);
      setSex(usr?.sex);
      setDOB(dayjs(usr?.dob));
      setImage(usr?.image);
      setPacienteId(usr?.pacienteId);
      setPhone(usr?.phone);
      setBirdPlace(usr?.birdPlace);
      setAlergias(usr?.alergias);
      setVacunas(usr?.vacunas);
    }
  };
  const getVacunasData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/vacunas',
      headers: { Authorization: token },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      dispatch({
        type: 'set-vacunas',
        value: result.Items,
      });
    }
  };
  const clearForm = () => {
    setName('');
    setLastName('');
    setSex('');
    setDOB(dayjs());
    setImage('');
    setPhone('');
    setBirdPlace('');
    setVacunas([]);
    setAlergias('');
    setLoading(false);
    setSuccess(false);
    setNameError(false);
    setLastNameError(false);
    setSexError(false);
    setDobError(false);
    setPhoneError(false);
    setBirdPlaceError(false);
    setActiveStep(0);
    setPacienteId(0);
  };

  const getProps = () => {
    const r = {
      name,
      lastName,
      sex,
      dob: dob.format('DD/MM/YYYY'),
      image,
      edit: false,
      primary: false,
    };
    return r;
  };
  useEffect(() => {
    clearForm();
    console.log('####DIALOG_UPDATE####');
    console.log(state.editPowerUserPatientInfo);
    getVacunasData();
    getData(state.editPowerUserPatientInfo?.pacienteId);
    setName(state.editPowerUserPatientInfo?.name);
    setLastName(state.editPowerUserPatientInfo?.lastName);
    setPacienteId(state.editPowerUserPatientInfo?.pacienteId);
  }, []);

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        open={state.dialogPowerUserPatientInfo}
        onClose={() => {
          dispatch({
            type: 'set-dialogPowerUserPatientInfo',
            value: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              Información Paciente
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogPowerUserPatientInfo',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>

        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {
          activeStep === 0 ? (
            <>
              <Fade in={activeStep === 0}>
                <Paper elevation={0}>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        autoFocus
                        required
                        disabled={editFields}
                        error={nameError}
                        margin="dense"
                        id="name"
                        label="Nombres"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setNameError(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        required
                        disabled={editFields}
                        error={lastNameError}
                        margin="dense"
                        id="lastName"
                        label="Apellidos"
                        type="text"
                        fullWidth
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                          setLastNameError(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        required
                        disabled={editFields}
                        error={sexError}
                        select
                        margin="dense"
                        id="sex"
                        label="Sexo"
                        type="text"
                        fullWidth
                        value={sex}
                        onChange={(e) => {
                          setSex(e.target.value);
                          setSexError(false);
                        }}
                      >
                        {sexChoices.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MuiPickersUtilsProvider utils={DayjsUtils} locale={esLocale}>
                        <DatePicker
                          required
                          disabled={editFields}
                          error={dobError}
                          disableFuture
                          fullWidth
                          disableToolbar
                          cancelLabel="Cancelar"
                          okLabel="Aceptar"
                          openTo="year"
                          format="DD/MM/YYYY"
                          label="Fecha De Nacimiento"
                          views={['year', 'month', 'date']}
                          value={dob}
                          onChange={(e) => {
                            setDobError(false);
                            setDOB(e);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        required
                        disabled={editFields}
                        margin="dense"
                        error={birdPlaceError}
                        id="birdPlace"
                        label="Lugar De Nacimiento"
                        type="text"
                        fullWidth
                        value={birdPlace}
                        onChange={(e) => {
                          setBirdPlace(e.target.value);
                          setBirdPlaceError(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}
          {
          activeStep === 1 ? (
            <>
              <Fade in={activeStep === 1}>
                <Paper elevation={0}>
                  <DialogContentText>
                    Vacunas Recientes Y Fechas
                  </DialogContentText>
                  <Grid container className={classes.root} spacing={2}>

                    {
                      state.vacunas.sort().filter(
                        (x) => vacunas.filter((y) => y.vacunaCode === x.vacunaCode).length,
                      ).map((v) => (
                        <Grid item xs={12} sm={6} md={6} key={`vacuna-${v.vacunaCode}`}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={vacunas.filter(
                                  (f) => f.vacunaCode === v.vacunaCode,
                                ).length}
                                // onChange={() => {
                                //   if (vacunas.filter(
                                //     (f) => f.vacunaCode === v.vacunaCode).length) {
                                //     setVacunas(vacunas.filter(
                                //       (e) => e.vacunaCode !== v.vacunaCode,
                                //     ));
                                //   } else {
                                //     const vTemp = [...vacunas];
                                //     vTemp.push({
                                //       vacunaCode: v.vacunaCode,
                                //       date: dayjs().valueOf(),
                                //     });
                                //     setVacunas(vTemp);
                                //     setSelectedVacuna(v.vacunaCode);
                                //     setConfirmDate(true);
                                //   }
                                // }}
                                name={`check-${v.vacunaCode}`}
                                color="secondary"
                              />
                          )}
                            label={(
                              <>
                                <Typography>
                                  {v.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {dayjs(vacunas.find((f) => f.vacunaCode === v.vacunaCode).date).format('DD/MM/YYYY')}
                                </Typography>
                              </>
)}
                          />
                        </Grid>
                      ))
                    }
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        disabled={editFields}
                        margin="dense"
                        error={nameError}
                        id="name"
                        label="Alergias"
                        type="text"
                        fullWidth
                        value={alergias}
                        onChange={(e) => {
                          setAlergias(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}
          {
          activeStep === 2 ? (
            <>
              <Fade in={activeStep === 2}>
                <Paper elevation={0}>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <img src={image || 'https://i1.wp.com/alkiansgroup.com/wp-content/uploads/2018/11/male-placeholder-image.jpg'} alt="Foto Perfil" className={classes.image} />
                    </Grid>
                  </Grid>
                </Paper>
              </Fade>
            </>
          ) : null
}
          {
activeStep === steps.length ? (
  <>
    <Fade in={activeStep === steps.length}>
      <Paper elevation={0}>
        <CardPaciente {...getProps()} />
      </Paper>
    </Fade>
  </>
) : null
}

        </DialogContent>
        <DialogActions>

          {activeStep === steps.length - 1 ? (
            <>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                // color="primary"
              >
                Regresar
              </Button>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    // setLoading(true);
                    // uploadData({
                    //   pacienteId,
                    //   accountEmail: state.accountEmail,
                    //   name,
                    //   lastName,
                    //   sex,
                    //   dob,
                    //   image,
                    //   vacunas,
                    //   alergias,
                    //   birdPlace,
                    //   phone,
                    // });
                    clearForm();
                    dispatch({
                      type: 'set-dialogPowerUserPatientInfo',
                      value: false,
                    });
                  }}
                >
                  Ok
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </>
          ) : (
            <>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                // color="primary"
              >
                Regresar
              </Button>
              <Button
                onClick={verifyValues}
                color="primary"
              >
                Siguiente
              </Button>
            </>
          )}

        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogPowerUserPatient;

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  MenuItem,
  Stepper,
  StepLabel,
  Step,
  Grow,
  Fade,
  Paper,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  ListSubheader,
  LinearProgress,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import Client from 'shopify-buy';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import clsx from 'clsx';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import esLocale from 'dayjs/locale/es';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  PhotoCamera, Close, Satellite, ExpandMore,
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';
// Components
import CardCita from './CardCita';
import CardTotal from './CardTotal';

const minPersons = 1;

const client = Client.buildClient({
  storefrontAccessToken: 'db49f66623fa938339b44ae88554da62',
  domain: 'private-health-club.myshopify.com',
  language: 'es-MX',
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function getSteps() {
  return ['Servicios', 'Usuarios'];
}

function DialogPayCita(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    pacientes, pacienteGrpups, user, accountEmail, payCitaId,
  } = state;

  // Form Varsaddress
  const [address, setAddress] = useState('');
  const [image, setImage] = useState('');
  const [phone, setPhone] = useState('');
  const [service, setService] = useState('');
  const [email, setEmail] = useState('');
  const [selected, setSelected] = useState([]);
  // Shopify
  const [products, setProducts] = useState([]);
  const [checkout, setCheckout] = useState({ lineItems: [] });
  const [citaId, setCitaId] = useState(0);

  // cita
  const [citaProducts, setCitaProducts] = useState([]);
  const [citaInfo, setCitaInfo] = useState({
    products: [],
    pago: 0,
    status: 0,
  });
  // Expire Cita
  const [expired, setExpired] = useState(false);

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // Error Form
  const [phoneError, setPhoneError] = useState(false);
  const [serviceError, setServiceError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [selectedError, setSelectedError] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const [gruposSelected, setGruposSelected] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  // Check Box
  const [vacunas, setVacunas] = useState([]);
  const [alergias, setAlergias] = useState('');

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const verifyAgainst = (e, value, setError) => {
    setError(e === value);
    return e === value;
  };
  const verifyValues = () => {
    switch (activeStep) {
      case 0:
        // eslint-disable-next-line no-unused-expressions
        // [
        //   verifyAgainst(service, '', setServiceError),
        // ].filter((e) => e === true).length ? '' : handleNext();
        handleNext();
        break;
      case 1:
        // eslint-disable-next-line no-unused-expressions
        [
          verifyAgainst(allUsers.length.toString(), '0', setSelectedError),
        ].filter((e) => e === true).length ? '' : createChekout();
        break;
      default:
        handleNext();
        console.log('INVALID');
    }
  };

  const getCita = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: `https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/citas/${payCitaId}`,
      headers: { Authorization: token },
      params: {
        accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    console.log('0000000000000000000');
    console.log(result);
    if (result?.Items) {
      if (result?.Items.length) {
        const Item = result?.Items[0];
        setCitaInfo(Item);
        setAllUsers(Item?.pacientes.map((e) => e.pacienteId));
        setUsersSelected(Item?.pacientes.map((e) => e.pacienteId));
        setCitaProducts(Item.products);
      } else {
        setExpired(true);
      }
    } else {
      setExpired(true);
    }
    setLoading(false);
  };
  const getGroupData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    setLoading(true);
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes/groups',
      headers: { Authorization: token },
      params: {
        accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      dispatch({
        type: 'set-pacienteGrpups',
        value: result.Items,
      });
    }
  };
  const getData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes',
      headers: { Authorization: token },
      params: {
        accountEmail,
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      dispatch({
        type: 'set-notificationMessage',
        value: 'Datos Actualizados',
      });
      dispatch({
        type: 'set-notification',
        value: true,
      });
      dispatch({
        type: 'set-pacientes',
        value: result.Items,
      });
    }
  };
  const uploadData = async (data) => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'put',
      url: `https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/citas/${payCitaId}`,
      headers: { Authorization: token },
      data,
    }).then((res) => res.data).catch((err) => err);
    console.log(result);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Base De Datos Actualizada',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    setLoading(false);
    setSuccess(true);
    dispatch({
      type: 'set-dialogPayCita',
      value: false,
    });
  };

  const clearForm = () => {
    setService('');
    setSelected([]);
    setPhone('');
    setEmail('');
    setEmailError(setSelectedError);
    setEmailError(false);
    setServiceError(false);
    setLoading(false);
    setSuccess(false);

    setActiveStep(0);
  };

  const getProducts = () => {
    client.product.fetchAll().then((res) => {
      console.log(res);
      setProducts(res);
    });
  };
  const createChekout = () => {
    // const variantId = products.find((e) => e.handle === service).variants[0].id;
    //  [{ variantId, quantity: selected.length }];

    const lineItems = products.filter((e) => citaProducts.map((p) => p.id).includes(e.id)).map((r) => ({
      variantId: r.variants[0]?.id,
      quantity: allUsers.length,
    }));

    const note = JSON.stringify({
      email: accountEmail,
      phone: user['custom:cellphone'],
      citaId: payCitaId,
    });
    client.checkout.create({ email: accountEmail, lineItems, note }).then((res) => {
      setCheckout(res);
      handleNext();
    });
  };
  const openCheckout = () => {
    window.open(checkout.webUrl);
  };
  const updateCheckOut = () => {
    const variantId = products.find((e) => e.handle === service).variants[0].id;
    const lineItemsToAdd = [{ variantId, quantity: selected.length }];
    const checkoutId = checkout.id;
    client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((res) => {
      console.log(res);
      setCheckout(res);
    });
  };
  useEffect(() => {
    console.log(accountEmail);
    clearForm();
    getProducts();
    setEmail(accountEmail);
    getData(state);
    getGroupData(state);
    getCita(state);

    console.log('$$$$$DIALOG_PAY_CITA$$$');
    console.log(user);
    return () => {
      window.history.pushState('main', 'privatehc', '/');
      dispatch({
        type: 'set-dialogPayCita',
        value: false,
      });
    };
  }, []);

  useEffect(() => {
    const usersIds = [...usersSelected];
    const g = pacienteGrpups.filter((e) => gruposSelected.includes(e.groupId));
    g.forEach((e) => e.members.forEach((f) => usersIds.push(f)));
    const f = usersIds.filter((e, i) => usersIds.indexOf(e) === i);
    console.log(f);
    setAllUsers(f);
  }, [gruposSelected, usersSelected]);

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        open={state.dialogPayCita}
        // onClose={() => {
        //   dispatch({
        //     type: 'set-dialogPayCita',
        //     value: false,
        //   });
        // }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              {expired ? 'Cita Expirada' : 'Confirmar Cita y Pagar Servicios'}
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogPayCita',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {loading ? <LinearProgress /> : null}
          {
            expired
              ? (
                <DialogContentText>
                  ⚠️Cita expirada. Por favor, contacte a su representante.
                </DialogContentText>
              )
              : (
                <>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  {
activeStep === 0 ? (
  <>
    <Fade in={activeStep === 0}>
      <Paper elevation={0}>
        <DialogContentText>
          Una vez confirmado el pago se agendará una cita.
        </DialogContentText>
        <List subheader={<ListSubheader>Estatus y Pago</ListSubheader>}>
          <ListItem>
            <ListItemText primary="Estatus" />
            <ListItemSecondaryAction><Chip size="small" label={citaInfo.status ? 'Completado' : 'Pendiente'} color={citaInfo.status ? 'secondary' : 'default'} /></ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Pago" />
            <ListItemSecondaryAction><Chip size="small" label={citaInfo.pago ? 'Completado' : 'Pendiente'} color={citaInfo.pago ? 'secondary' : 'default'} /></ListItemSecondaryAction>
          </ListItem>
        </List>
        <List subheader={<ListSubheader>Productos asociados con esta cita.</ListSubheader>}>
          {
                      citaProducts.map((p, i) => (
                        <ListItem button key={p?.id}>
                          <ListItemText
                            primary={p?.title}
                            secondary={(
                              <>
                                {p?.productType}
                                <Typography
                                  style={{
                                    marginTop: 5,
                                  }}
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {products.find((e) => e.id === p?.id)?.description}
                                </Typography>
                              </>
                        )}
                          />
                        </ListItem>
                      ))
                    }
        </List>
      </Paper>
    </Fade>
  </>
) : null
}

                  {
          activeStep === 1 ? (
            <>
              <Fade in={activeStep === 1}>
                <Paper elevation={0}>
                  <DialogContentText>
                    Selección de grupos o usuarios para esta cita
                  </DialogContentText>
                  {
                        selectedError ? (
                          <Typography variant="body1" gutterBottom color="secondary" style={{ marginTop: 5 }}>
                            Es necesario seleccionar al menos a un usuario o grupo con usuarios.
                          </Typography>
                        ) : null
                      }
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      Grupos
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container className={classes.root} spacing={2}>
                        {
                      pacienteGrpups.map((p) => (
                        <Grid item xs={12} sm={12} md={12} key={`group-${p?.groupId}`}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={gruposSelected.includes(p.groupId)}
                                name={`check-group-${p?.groupId}`}
                                color="secondary"
                                onChange={() => {
                                  if (gruposSelected.filter((f) => f === p.groupId).length) {
                                    setGruposSelected(gruposSelected.filter(
                                      (e) => e !== p.groupId,
                                    ));
                                  } else {
                                    const vTemp = [...gruposSelected];
                                    vTemp.push(p.groupId);
                                    setGruposSelected(vTemp);
                                  }
                                  setSelectedError(false);
                                }}
                              />
                          )}
                            label={p?.groupName}
                          />
                        </Grid>
                      ))
                    }
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      id="panel2bh-header"
                    >
                      Usuarios
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container className={classes.root} spacing={2}>
                        {
                      pacientes.map((p) => (
                        <Grid item xs={12} sm={12} md={12} key={`user-${p?.pacienteId}`}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={usersSelected.includes(p.pacienteId)}
                                name={`check-user-${p?.pacienteId}`}
                                color="secondary"
                                onChange={() => {
                                  if (usersSelected.filter((f) => f === p.pacienteId).length) {
                                    setUsersSelected(usersSelected.filter(
                                      (e) => e !== p.pacienteId,
                                    ));
                                  } else {
                                    const vTemp = [...usersSelected];
                                    vTemp.push(p.pacienteId);
                                    setUsersSelected(vTemp);
                                  }
                                  setSelectedError(false);
                                }}
                              />
                          )}
                            label={`${p.name} ${p.lastName}`}
                          />
                        </Grid>
                      ))
                    }
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                </Paper>
              </Fade>
            </>
          ) : null
}
                  {
activeStep === steps.length ? (
  <>
    <Fade in={activeStep === steps.length}>
      <Paper elevation={0}>
        <DialogContentText>
          Una vez que su pago sea confirmado, contactaremos para programar su cita
        </DialogContentText>
        {
          allUsers.length > minPersons ? (
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 5 }}>
                <Typography variant="button" gutterBottom style={{ fontWeight: 'bold' }}>
                  Beneficio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="button" gutterBottom>
                  ⚠️Su grupo recibió el beneficio de seleccionar la ubicación para agendar la aplicación.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField id="address" fullWidth autoFocus label="Escriba La Dirección Completa" variant="filled" value={address} onChange={(e) => setAddress(e.target.value)} />
              </Grid>
            </Grid>
          ) : null
        }

        <List subheader={<ListSubheader style={{ fontWeight: 'bold' }}>Mi Carrito</ListSubheader>}>
          {

products.filter((e) => citaProducts.map((p) => p.id).includes(e.id)).map((f) => (
  <ListItem key={`product_chart_${f.id}`}>
    <ListItemText primary={f?.title} secondary={f?.productType} />
    <ListItemSecondaryAction>
      <Typography syle={{ fontWeight: 'bolder' }} color="primary">
        $
        {' '}
        {(parseFloat(f?.variants[0]?.price) * allUsers.length).toFixed(2)}
      </Typography>
    </ListItemSecondaryAction>
  </ListItem>
))
          }

          <ListItem style={{ backgroundColor: '#d2d2d2' }}>
            <ListItemText primary="TOTAL" />
            <ListItemSecondaryAction>
              <Typography syle={{ fontWeight: 'bolder' }} color="primary">
                $
                {' '}
                {products.filter((e) => citaProducts.map((p) => p.id).includes(e.id)).map((r) => parseFloat(r.variants[0]?.price) * allUsers.length).reduce((t, n) => t + n).toFixed(2)}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>
    </Fade>
  </>
) : null
}

                </>
              )
          }
        </DialogContent>
        <DialogActions>

          {
            expired ? (
              <>
                <Button
                  onClick={() => {
                    clearForm();
                    dispatch({
                      type: 'set-dialogPayCita',
                      value: false,
                    });
                  }}
                >
                  Cancelar
                </Button>
              </>
            )
              : (
                <>
                  {activeStep === steps.length ? (
                    <>
                      <Button
                        onClick={() => {
                          clearForm();
                          dispatch({
                            type: 'set-dialogPayCita',
                            value: false,
                          });
                        }}
                      >
                        Cancelar
                      </Button>
                      <div className={classes.wrapper}>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={loading || citaInfo.pago}
                          onClick={() => {
                            openCheckout();
                            setLoading(true);
                            uploadData({
                              ...citaInfo,
                              ...{
                                pacientes: pacientes.filter((e) => allUsers.includes(e.pacienteId)).map((m) => ({
                                  pacienteId: m.pacienteId,
                                  name: m.name,
                                  lastName: m.lastName,
                                  status: 0,
                                })),
                                updated: dayjs().valueOf(),
                                address,
                                place: address ? 'custom' : '',
                              },
                            });
                          }}
                        >
                          Pagar
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        Regresar
                      </Button>
                      <Button
                        onClick={verifyValues}
                        color="primary"
                      >
                        Siguiente
                      </Button>
                    </>
                  )}
                </>
              )
          }

        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogPayCita;

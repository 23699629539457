/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  footer: {
    // backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(8),
    backgroundColor: '#dbdee0',
    color: '#56676b',
    padding: theme.spacing(6, 0),
  },
  font: {
    color: '#56676b',
  },
  fontBold: {
    color: '#56676b',
    fontWeight: 'bold',
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Typography variant="body2" color="textSecondary" align="center" className={classes.font}>
          {'© '}
          {new Date().getFullYear()}
          {' '}
          <Link color="inherit" href="https://www.privatehc.com/" className={classes.fontBold}>
            PRIVATEHC
          </Link>
          {' All Rights Reserved.'}
        </Typography>
      </Container>
    </footer>
  );
}

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  Chip,
  CircularProgress,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { PhotoCamera, Close } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
// Store
import { store } from '../state/store';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 160,
  },
  image: {
    width: '100%',
    // height: '90%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function DialogContact(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  // Form Vars
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [sex, setSex] = useState('');
  const [dob, setDOB] = useState();
  const [image, setImage] = useState('');
  const [msg, setMsg] = useState('');

  // Lader
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // Error Form
  const [msgError, setMsgError] = useState(false);

  const [sendEmail, setSendEmail] = useState(true);
  const [sendSMS, setSendSMS] = useState(true);
  const [chars, setChars] = useState(0);
  const [service, setService] = useState('');

  // Check Box

  const uploadData = async (data) => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'post',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/contacto',
      headers: { Authorization: token },
      data,
    }).then((res) => res.data).catch((err) => err);
    console.log(result);
    dispatch({
      type: 'set-notificationMessage',
      value: 'Base De Datos Actualizada',
    });
    dispatch({
      type: 'set-notification',
      value: true,
    });
    setLoading(false);
    setSuccess(true);
    dispatch({
      type: 'set-dialogContact',
      value: false,
    });
    dispatch({
      type: 'set-editContact',
      value: {},
    });
  };
  const verifyValues = () => {
    if (msg.length === 0) {
      setMsgError(true);
    } else {
      console.log('SEND MSG');
      uploadData({
        email: state?.editContact?.email,
        phone: state?.editContact?.phone,
        msg,
        sendEmail,
        sendSMS,
      });
    }
  };
  const clearForm = () => {
    setMsgError(false);
    setMsg('');
    setSendEmail(true);
    setSendSMS(true);
    setChars(0);
  };

  const getProps = () => {
    const r = {
      name,
      lastName,
      sex,
      dob: dob.format('DD/MM/YYYY'),
      image,
      edit: false,
      primary: false,
    };
    return r;
  };
  useEffect(() => {
    clearForm();
  }, []);

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        open={state.dialogContact}
        onClose={() => {
          dispatch({
            type: 'set-dialogContact',
            value: false,
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              Contactar Usuario
              {' '}
              {
                sendSMS && msg.length > 0 ? <Chip label={chars} variant="outlined" /> : null
              }

            </Grid>
            <Grid item>
              <IconButton
                aria-label="Cerrar"
                onClick={() => {
                  dispatch({
                    type: 'set-dialogContact',
                    value: false,
                  });
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {sendSMS ? (
            <DialogContentText>
              Los mensajes de texto están limitados a 160 caracteres.
            </DialogContentText>
          ) : null}

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControlLabel
                control={<Checkbox checked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)} name="checkedA" />}
                label={(
                  <>
                    Correo Electrónico
                    <Typography>
                      {state?.editContact?.email}
                    </Typography>

                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControlLabel
                control={<Checkbox checked={sendSMS} onChange={(e) => setSendSMS(e.target.checked)} name="checkedA" />}
                label={(
                  <>
                    Mensaje SMS
                    <Typography>
                      {state?.editContact?.phone}
                    </Typography>

                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                required
                multiline
                rows={3}
                margin="dense"
                error={msgError}
                id="message"
                label="Mensaje"
                type="text"
                fullWidth
                value={msg}
                onChange={(e) => {
                  setChars(e.target.value.length);
                  setMsg(e.target.value);
                  setMsgError(false);
                }}
              />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch({
                type: 'set-dialogContact',
                value: false,
              });
            }}
          >
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              disable={loading}
              onClick={verifyValues}
              color="primary"
            >
              Enviar
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogContact;

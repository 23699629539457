/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  Fab,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardActionArea,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  Chip,
  Checkbox,
  Paper,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Room } from '@material-ui/icons';
import GoogleMapReact from 'google-map-react';
import dayjs from 'dayjs';
// Store
import { store } from '../state/store';

const key = 'AIzaSyDnsaH6YtS51AxYYGIb9Q6tnIiN0JrppYQ';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    height: 200,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function CardCita(props) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  const [open, setOpen] = useState(false);

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          {
            props.servicio ? (
              <Typography gutterBottom variant="h5">
                {props.servicio}
              </Typography>
            ) : null
          }
          <List className={classes.root}>
            <ListItem>
              <ListItemText primary="Fecha Cita" secondary={props?.cita ? dayjs(props.cita).format('DD/MM/YYYY hh:mm A') : '-'} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Lugar Cita" secondary={props?.place || '-'} />
            </ListItem>
            {
              props.coordinates
                ? (
                  <ListItem>
                    <Paper style={{ width: '470px', height: '228px' }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key }}
                        defaultCenter={props?.coordinates}
                        defaultZoom={12}
                      >
                        <Room
                          style={{ color: '#e94b36' }}
                          lat={props?.coordinates?.lat}
                          lng={props?.coordinates?.lng}
                          text="Lugar"
                        />
                      </GoogleMapReact>
                    </Paper>
                  </ListItem>
                )
                : null
            }
            <ListItem>
              <ListItemText primary="Vacunacion" />
              <ListItemSecondaryAction><Chip size="small" label={props.status ? 'Completado' : 'Pendiente'} color={props.status ? 'secondary' : 'default'} /></ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Pago" />
              <ListItemSecondaryAction><Chip size="small" label={props.pago ? 'Completado' : 'Pendiente'} color={props.pago ? 'secondary' : 'default'} /></ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Dosis" secondary={props?.dosis || 0} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Usuarios" secondary={props?.pacientes.length || 0} />
            </ListItem>
            <ListItem button onClick={() => setOpen(!open)}>
              <ListItemText primary="Servicios" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {
                props.products.map((e) => (
                  <List component="div" disablePadding key={e.id}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary={e.title} secondary={e.productType} />
                    </ListItem>
                  </List>
                ))
              }
            </Collapse>
            {/* <ListItem button onClick={() => setOpen(!open)}>
              <ListItemText primary="Pacientes" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {
                props.pacientes.map((e) => (
                  <List component="div" disablePadding key={e.pacienteId}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary={`${e?.name} ${e?.lastName}`} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          checked={e?.status}
                          inputProps={{ 'aria-labelledby': e.pacienteId }}
                        />

                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                ))
              }
            </Collapse> */}
          </List>
        </CardContent>
      </Card>
    </>
  );
}

export default CardCita;

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  Fab,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { Add, Autorenew, MoreHoriz } from '@material-ui/icons';
import axios from 'axios';
import { Auth } from 'aws-amplify';
// Components
import AppBar from '../components/AppBar';
import CardPaciente from '../components/CardPaciente';
import DialogNewPaciente from '../components/DialogNewPaciente';
import DialogUpdatePaciente from '../components/DialogUpdatePaciente';
import DialogNewUserGroup from '../components/DialogNewUserGroup';
import DialogNewGroup from '../components/DialogNewGroup';
import CardPacienteGroup from '../components/CardPacienteGroup';
import DialogEditGroup from '../components/DialogEditGroup';

// Store
import { store } from '../state/store';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  media: {
    height: 160,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fabProgress: {
    // color: green[500],
    position: 'absolute',
    // top: -6,
    // left: -6,
    zIndex: 1,
  },
}));

function Registro() {
  const classes = useStyles();

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { pacientes, pacienteGrpups } = state;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const getAdditionalData = async (lastEvaluatedKey, Items) => {
    dispatch({
      type: 'set-loading',
      value: true,
    });
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail,
        lastEvaluatedKey: JSON.stringify(lastEvaluatedKey),
      },
    }).then((res) => res.data).catch((err) => err);
    console.log('%%%%%%%%%%%%%');
    console.log(result);
    const res = Items.concat(result.Items);
    // eslint-disable-next-line no-prototype-builtins
    if (result.hasOwnProperty('LastEvaluatedKey')) {
      getAdditionalData(result.LastEvaluatedKey, res);
    } else {
      dispatch({
        type: 'set-notificationMessage',
        value: 'Datos Actualizados',
      });
      dispatch({
        type: 'set-notification',
        value: true,
      });
      dispatch({
        type: 'set-pacientes',
        value: res,
      });
      setLoading(false);
    }
  };
  const getData = async (Items = []) => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    setLoading(true);
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail, // state.accountEmail
      },
    }).then((res) => res.data).catch((err) => err);
    const res = result.Items;
    // eslint-disable-next-line no-prototype-builtins
    if (result.hasOwnProperty('LastEvaluatedKey')) {
      getAdditionalData(result.LastEvaluatedKey, res);
    } else {
      dispatch({
        type: 'set-notificationMessage',
        value: 'Datos Actualizados',
      });
      dispatch({
        type: 'set-notification',
        value: true,
      });
      dispatch({
        type: 'set-pacientes',
        value: res,
      });
      setLoading(false);
    }
  };
  const getGroupData = async () => {
    const token = await Auth.currentSession()
      .then((res) => res.idToken.jwtToken).catch((err) => {
        console.log('###TOKEN_ERROR###');
        console.log(err);
        return '';
      });
    setLoading(true);
    const result = await axios({
      method: 'get',
      url: 'https://sirbzyuhv5.execute-api.us-east-1.amazonaws.com/DEV/pacientes/groups',
      headers: { Authorization: token },
      params: {
        accountEmail: state.accountEmail, // state.accountEmail
      },
    }).then((res) => res.data).catch((err) => err);
    if (result?.Items) {
      dispatch({
        type: 'set-pacienteGrpups',
        value: result.Items,
      });
    }
    getData();
  };
  useEffect(() => {
    getGroupData();
    dispatch({
      type: 'set-title',
      value: 'Registro Usuarios',
    });
  }, []);

  return (
    <>
      {
        state.dialogNewGroup ? <DialogNewGroup /> : null
      }
      {
        state.dialogEditGroup ? <DialogEditGroup /> : null
      }
      {
        state.dialogNewUserGroup ? <DialogNewUserGroup /> : null
      }
      {
        state.dialogNewPatient ? <DialogNewPaciente /> : null
      }
      {
        state.dialogEditPatient ? <DialogUpdatePaciente /> : null
      }
      <>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" alignItems="center">
                <Grid item>
                  <Tooltip title="Crear Nuevos Usuarios y Grupos." aria-label="Crear">
                    <div className={classes.wrapper}>
                      <Fab
                        color={loading ? 'default' : 'primary'}
                        aria-label="add"
                        onClick={() => {
                          dispatch({
                            type: 'set-dialogNewUserGroup',
                            value: true,
                          });
                        // dispatch({
                        //   type: 'set-dialogNewPatient',
                        //   value: true,
                        // });
                        }}
                      >
                        { loading ? <MoreHoriz />

                          : (

                            <Add />

                          )}
                      </Fab>
                      {loading && <CircularProgress size={68} className={classes.fabProgress} color={loading ? 'default' : 'primary'} />}
                    </div>
                  </Tooltip>
                  <Typography variant="button" align="center" gutterBottom>
                    Crear Usuarios y Grupos
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {
              !loading && pacienteGrpups.length === 0 && pacientes.length === 0 ? (
                <Grid container justify="center" alignItems="center">
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography variant="h6" align="center" gutterBottom>
                      ⚠️No hay registros que mostrar
                    </Typography>
                  </Grid>
                </Grid>
              ) : null
            }
          <Grid container spacing={2}>
            {
              pacienteGrpups.map((e, i) => (
                <Grid key={`registro_group_${i}`} item xs={12} sm={6} md={4}>
                  <CardPacienteGroup {...e} edit />
                </Grid>
              ))
            }
          </Grid>
          <Grid container spacing={2}>

            {
              pacientes.map((e, i) => (
                <Grid key={`registro_${i}`} item xs={12} sm={6} md={4}>
                  <CardPaciente {...e} edit />
                </Grid>
              ))
            }
          </Grid>
        </Container>
      </>
    </>
  );
}

export default Registro;
